export default {
  "name": "comic",
  "styles": {
    "background": "rgba(116, 140, 160, 1)",
    "fontFamily": "Bowlby One SC",
    "topbarBackground": "rgba(69, 74, 77, 1)",
    "topbarText": "#ffffff",
    "sequenceBackground": "rgba(161, 166, 126, 0)",
    "sequenceText": "#ffffff",
    "buttonBackground": "rgba(0, 0, 0, 0.23)",
    "buttonText": "rgba(255, 255, 255, 1)",
    "assetsBackground": "rgba(0, 0, 0, 0.23)",
    "assetsFill": "#ffffff",
    "sfx": {},
    "animations": {
      "sequence": "zoom",
      "objectModal": "zoom"
    },
    "textStyle": {
      "type": "superhero",
      "params": {
        "size": 3.5,
        "color2": "rgba(158, 158, 158, 1)",
        "color": "rgba(83, 82, 82, 1)"
      }
    }
  }
}