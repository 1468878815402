export default {
  name: 'army',
  styles: {
    textStyle: {
      params: {
        color: 'rgba(156, 221, 91, 1)',
        color2: 'rgba(65, 117, 5, 1)',
        size: 1.5
      },
      type: 'glow'
    },
    animations: {
      sequence: 'fade',
      objectModal: 'zoom',
      objectModalDecorator: 'star'
    },
    background : 'rgba(104, 151, 53, 1)',
    fontFamily : 'Black Ops One',
    buttonBackground : 'rgba(255, 255, 255, 0.18)',
    sequenceBackground : 'rgba(125, 179, 69, 0.9)',
    assetsBackground : 'rgba(65, 117, 5, 1)',
    sequenceText : 'rgba(51, 51, 48, 1)',
    topbarBackground : 'rgba(126, 211, 33, 0.69)',
    topbarText : 'rgba(65, 117, 5, 1)',
    assetsFill : 'rgba(184, 233, 134, 1)',
    sfx: {}
  }
}