import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useAnimatePresence } from 'use-animate-presence'


const BackgroundImage = ({url, options, bgStylesFix={}}) => {
  const [imageUrl, setImageUrl] = useState(url)
  const [lastImageUrl, setLastImageUrl] = useState(url)
  const [imageOptions, setImageOptions] = useState({})

  const animatedImg = useAnimatePresence({
    variants: { opacity: { from: 0, to: 1 }},
    initial: url ? 'visible' : 'hidden',
    duration: 300
  })

  useEffect(() => {
    if (!!lastImageUrl !== !!url) {
      animatedImg.togglePresence()
    }
    setLastImageUrl(url)
    if (url) {
      setImageUrl(url)
    }
  }, [url])

  useEffect(() => {
    if (options) {
      let opts = {
        align: {
          vertical: options.vAlign,
          horizontal: options.hAlign
        },
        styles: {
          opacity: options.opacity
        }
      }
      if (options.scale && options.scale.base === 'height') {
        opts.styles = {
          ...opts.styles,
          width: 'auto',
          height: options.scale.value + options.scale.kind
        }
      } else if (options.scale && options.scale.base === 'width') {
        opts.styles = {
          ...opts.styles,
          height: 'auto',
          width: options.scale.value + options.scale.kind
        }
      }
      setImageOptions(opts)
    } else {
      setImageOptions({})
    }
  }, [options])

  return (
    <Fragment>
      { animatedImg.isRendered && (
        <div className='story-background-container' ref={animatedImg.ref} style={{
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: imageOptions.align ? imageOptions.align.horizontal : 'center',
          alignItems: imageOptions.align ? imageOptions.align.vertical : 'center'
        }}>
          <img
            alt=''
            src={imageUrl}
            style={{...imageOptions.styles, ...bgStylesFix}}
          />
        </div>
      )}
    </Fragment>
  )
}

const StoryBackground = (props) => {
  const {
    theme,
    images,
    bgStylesFix
  } = props

  const [url, setUrl] = useState(null)
  const [opts, setOpts] = useState(null)
  const [stylesFix, setStylesFix] = useState({})

  useEffect(() => {
    if (bgStylesFix && typeof bgStylesFix === 'function') {
      setStylesFix(bgStylesFix(theme))
    } else if (bgStylesFix) {
      setStylesFix(bgStylesFix)
    }
  }, [theme, bgStylesFix])

  useEffect(() => {
    if (images && theme && theme.image && images[theme.image.id]) {
      setUrl(images[theme.image.id].url)
      setOpts(theme.image.options)
    } else {
      setUrl(null)
    }
  }, [theme, images])

  return <BackgroundImage {...{url, options: opts, bgStylesFix: stylesFix}} />
}

export default StoryBackground