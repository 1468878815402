export const superhero = ({color='#000', size=1}) => {
  const shortShadow = `${.02 * size}em`
  const shadow = `${.06 * size}em`
  return {
    textShadow: `
      0 -${shortShadow} 0 ${color},
      0 -${shortShadow} 0 ${color},
      0 ${shortShadow} 0 ${color},
      0 ${shortShadow} 0 ${color},
      -${shortShadow} 0 0 ${color},  
      ${shortShadow} 0 0 ${color},
      -${shortShadow} 0 0 ${color},
      ${shortShadow} 0 0 ${color},
      -${shortShadow} -${shortShadow} 0 ${color},  
      ${shortShadow} -${shortShadow} 0 ${color},
      -${shortShadow} ${shortShadow} 0 ${color},
      ${shortShadow} ${shortShadow} 0 ${color},
      -${shortShadow} ${shadow} 0 ${color},
      0 ${shadow} 0 ${color},
      ${shortShadow} ${shadow} 0 ${color}`
  }
}

export const glow = ({color='#000', color2='#fff', size=1}) => ({
  textShadow: `
    0 0 ${.05 * size}em ${color2},
    0 0 ${.1 * size}em ${color}`
})

export const retro = ({color='#000', size=1}) => ({
  textShadow: `${color} ${.04 * size}em ${.03 * size}em 0`
})

export const inset = ({color='#000', size=1}) => {
  const styleSize = `${.05 * size}em`
  return {
    textShadow: `${color} ${styleSize} ${styleSize} 0`
  }
}

export const longShadow = ({color='#000', size=1}) => {
  const shadows = []
  let s = size
  while (s > 0) {
    shadows.push(s)
    s -= .25
  }
  return {
    textShadow: shadows.map(v => `${v}px ${v}px ${color}`).join(',')
  }
}