import React from 'react'
import { ReactSVG } from 'react-svg'
import { sanitize } from 'dompurify'

const getSafeTemplate = (reactElement) => sanitize(reactElement)

const InlineSvg = ({svg})  => (
  <div
    className='fallback'
    dangerouslySetInnerHTML={{__html: getSafeTemplate(svg)}}
  />
)

const SvgIcon = ({icon, className='', style={}}) => {
  const rawSvgDataSplit = icon && icon.split('svg+xml,')
  const currentIcon = rawSvgDataSplit && rawSvgDataSplit.length === 2 ? decodeURIComponent(rawSvgDataSplit[1]) : icon
  return (
    <ReactSVG
      src={currentIcon && !currentIcon.startsWith('<svg ') ? currentIcon : ''}
      className={className}
      style={style}
      fallback={() => <InlineSvg svg={currentIcon} />}
    />
  )
}

export default SvgIcon