export default {
  "name": "pastel",
  "styles": {
    "background": "rgba(166, 151, 126, 1)",
    "fontFamily": "Cantarell",
    "topbarBackground": "rgba(69, 74, 77, 1)",
    "topbarText": "#ffffff",
    "sequenceBackground": "rgba(161, 166, 126, 1)",
    "sequenceText": "#ffffff",
    "buttonBackground": "rgba(166, 132, 126, 1)",
    "buttonText": "#ffffff",
    "assetsBackground": "rgba(82, 107, 21, 0)",
    "assetsFill": "#ffffff",
    "sfx": {},
    "animations": {
      "sequence": "fade",
      "objectModal": "fade"
    },
    "textStyle": {
      "type": "glow",
      "params": {
        "size": 1,
        "color2": "rgba(158, 158, 158, 1)"
      }
    }
  }
}