import React, { Fragment } from 'react'
import { Segment, Button } from 'semantic-ui-react'
import { cutText } from './utils'
import StoryModalHeader from './story-modal-header'
import { PlayerLocalesContext } from './story-player-locales'
import Logo from './logo.svg'
import './story-about-screen.css'
import PlayerVersion from './player-version'

const StoryAboutScreen = (props) => {
  const {
    story,
    onClose,
    showPlatformInfo
  } = props

  const { description, image, imageLocal } = story.meta

  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Segment className='story-about-screen' inverted padded='very' style={{ width: 350, padding: '2.5em 3em'}}>
          <Button primary circular icon='close' onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }} />
          <Fragment>
            <StoryModalHeader story={story} />
            { (imageLocal || image) && (
              <div>
                <img style={{maxHeight: 225, overflow: 'hidden', width: '100%'}} src={imageLocal || image} alt='' className='cover' />
              </div>
            )}
            <p style={{ marginTop: '0.3em' }}>{ cutText(description, 260, true) }</p>
            { showPlatformInfo && (
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', color: '#878787' }}>
                <img alt='moiki logo' src={Logo} style={{ width: 50, height: 50 }} />
                <p style={{ marginLeft: '1em', fontSize: '.8em' }}><em><a href="https://moiki.fr" target="_blank" rel="noreferrer noopener">Moiki.fr</a> {strings.ITS_FREE}</em></p>
              </div>
            )}
          </Fragment>
          <PlayerVersion />
        </Segment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default StoryAboutScreen