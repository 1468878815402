import React, { Fragment } from 'react'
import { Segment, Button, Divider } from 'semantic-ui-react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { PlayerLocalesContext } from './story-player-locales'
import 'react-circular-progressbar/dist/styles.css'
import PlayerVersion from './player-version'

const StoryPlayerOptions = (props) => {
  const {
    story,
    stats,
    onReset,
    onClose,
    onShowCredits,
    onRestart
  } = props

  const {
    numSequencesShown,
    numSequencesTotal,
    numHeroesShown,
    numHeroesTotal,
    numObjectsShown,
    numObjectsTotal,
    numUndo,
    numReplays,
    numVictories,
    numDefeats
  } = stats

  const sequencesPercentage = Math.round((numSequencesShown / numSequencesTotal) * 100)
  let heroesPercentage = -1
  if (numHeroesTotal > 0) {
    heroesPercentage = Math.round((numHeroesShown / numHeroesTotal) * 100)  
  }
  let objectsPercentage = -1
  if (numObjectsTotal > 0) {
    objectsPercentage = Math.round((numObjectsShown / numObjectsTotal) * 100)
  }
  let totalPercentage = 0
  if (heroesPercentage >= 0 && objectsPercentage >= 0) {
    totalPercentage = (sequencesPercentage + (heroesPercentage + objectsPercentage) / 2) / 2
  } else if (heroesPercentage < 0 && objectsPercentage < 0) {
    totalPercentage = sequencesPercentage
  } else {
    totalPercentage = (sequencesPercentage + heroesPercentage + objectsPercentage + 1) / 2
  }

  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Segment inverted padded='very' style={{ width: 350}}>
          <Button primary circular icon='close' onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }} />
          <Divider inverted horizontal style={{ marginTop: 0 }}>{strings.PROGRESSION}</Divider>
          <div style={{ width: 70, height: 70, margin: '1em auto' }}>
            <CircularProgressbar
              value={totalPercentage}
              text={`${Math.round(totalPercentage)}%`}
              strokeWidth={10}
              styles={{
                text: {
                  fontSize: '2em',
                  fontWeight: 'bold'
                }
              }}
            />
          </div>
          <div>{strings.SEQ_FOUND} <b>{`${numSequencesShown} / ${numSequencesTotal}`}</b></div>
          { numHeroesTotal > 0 && (<div>{strings.HEROES_FOUND} <b>{`${numHeroesShown} / ${numHeroesTotal}`}</b></div>)}
          { numObjectsTotal > 0 && (<div>{strings.OBJ_FOUND} <b>{`${numObjectsShown} / ${numObjectsTotal}`}</b></div>)}
          <Divider inverted horizontal>{strings.STATS}</Divider>
          { story.meta && story.meta.options.allowBack && (
            <div>{strings.NUM_UNDO} <b>{numUndo}</b></div>
          )}
          <div>{strings.NUM_GAMES} <b>{numReplays}</b></div>
          <div>{strings.NUM_VICTORIES} <b>{numVictories}</b></div>
          <div>{strings.NUM_DEFEATS} <b>{numDefeats}</b></div>
          <Divider />
          <Fragment>
            <Button size='large' inverted circular icon='repeat' content={strings.RESTART} onClick={onRestart} style={{ marginBottom: '1em' }} /><br/>
          </Fragment>
          <Button size='small' inverted circular content={strings.RESET} onClick={onReset} />
          <Divider inverted />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button className='link-button' basic inverted size='mini' onClick={onShowCredits}>{strings.SHOW_CREDITS}</Button>
          </div>
          <PlayerVersion />
        </Segment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default StoryPlayerOptions