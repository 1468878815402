import React, { Fragment } from 'react'
import { getAuthor } from './utils'
import { PlayerLocalesContext } from './story-player-locales'
import { unsecableRawSpaces } from './story-player-utils'

const AuthorInfo = ({strings, story}) => {
  const author = getAuthor(story.meta)
  return (
    <div style={{ fontStyle: 'italic', color: '#9e9a9a', marginBottom: '1em' }}>
      { story.meta.originalAuthor ? (
        <Fragment>
          <span>{strings.STORY_BY} {story.meta.originalAuthor}</span><br/>
          <span>({strings.ADAPTED_BY} {author})</span>
        </Fragment>
      ) : (
        strings.STORY_BY + ' ' + author
      ) }
    </div>
  )
}

const StoryModalHeader = ({story, showIfid=true}) => {
  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Fragment>
          <div style={{ fontStyle: 'italic' }}>{ strings.PRESENT }</div>
          <h2 style={{ marginTop: '0.3em', marginBottom: '0.3em' }}>{ unsecableRawSpaces(story.meta.name) }</h2>
          <AuthorInfo strings={strings} story={story} />
          { (story.meta.ifid && showIfid) && (
            <div style={{
              fontStyle: 'italic',
              fontSize: '.8em',
              userSelect: 'text',
              margin: '-10px -25px 5px',
              textAlign: 'center',
              color: '#8a8a8a' }}
            >IFID : {story.meta.ifid}</div>
          )}
        </Fragment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default StoryModalHeader