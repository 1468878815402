import * as easings from 'd3-ease'

const animationKinds = {
  fade: {
    label: 'Fade',
    data: {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 350, easing: easings.easeCubic },
    }
  },
  zoom: {
    label: 'Zoom',
    data: {
      from: { transform: 'scale(0)' },
      enter: { transform: 'scale(1)' },
      leave: { transform: 'scale(0)' },
      config: { duration: 350, easing: easings.easeCubic },
    }
  },
  rotateZoom: {
    label: 'Rotation',
    data: {
      from: { transform: 'scale(0) rotate(0deg)' },
      enter: { transform: 'scale(1) rotate(720deg)' },
      leave: { transform: 'scale(0) rotate(1080deg)' },
      config: { duration: 550, easing: easings.easeCubic },
    }
  }
}

const decoratorKinds = ['star']

const getAnimationStyle = (id) => {
  const anim = animationKinds[id] || animationKinds.zoom
  return anim.data
}

export default {
  kinds: animationKinds,
  decorators: decoratorKinds,
  getAnimationStyle 
}