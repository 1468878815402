import React, {useEffect, useRef, useState} from 'react'
import StoryPlayer from './story-player'
import { useSyncedLocalStorage } from './use-synced-local-storage'
import { convertRoutines } from './story-player/story-player-utils'
import CryptoJS from 'crypto-js'
import base64 from 'base-64'
import utf8 from 'utf8'
import './App.css'

const availableLanguages = ['fr', 'en']

const convertArrayToObject = (array, key='id') => {
  const initialValue = {}
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue)
}

function App() {
  const playerRef = useRef(null)

  const [story, setStory] = useState(null)
  const [storage, setStorage] = useSyncedLocalStorage('moiki-player', {})

  const cryptedData = window.moiki_story
  const title = document.querySelector('meta[property="og:title"]').content
  const encJSON = utf8.decode(base64.decode(cryptedData))
  const data = JSON.parse(CryptoJS.AES.decrypt(encJSON, title).toString(CryptoJS.enc.Utf8))
  let lang = window.language

  useEffect(() => {
    if (data.routines && data.routines.length > 0) {
      convertRoutines(data)
    }
    setStory({
      ...data,
      sequences: convertArrayToObject(data.sequences),
      images: convertArrayToObject(data.images || []),
      assets: convertArrayToObject(data.assets || []),
      sounds: convertArrayToObject(data.sounds || []),
      counters: convertArrayToObject(data.counters || []),
      textvars: convertArrayToObject(data.textvars || []),
      themes: data.themes || [{...data.theme, identifier: 'default', idName: 'default'}]
    })
  }, [])

  if (!data || !lang) {
    return null
  }

  if (!availableLanguages.includes(lang)) {
    lang = 'fr'
  }

  const onPlayerReady = () => {
    if (storage && playerRef.current) {
      const game = storage[story._id]
      if (game) {
        const currentVersion = story.meta.modificationDate
        if (game.version === currentVersion) {
          playerRef.current.loadGame({
            ...game,
            delay: game.date ? new Date().getTime() - game.date : 0
          })
        } else {
          console.log('oh oh ! there is a backup for this game, but the version has changed since then! will skip it :(')
        }
      } else {
        // no game to load !
      }
    }
  }

  const onSavePoint = (savePoints) => {
    const { data, stats } = savePoints
    if (storage && story && data.length > 0 && data[data.length - 1].path && data[data.length - 1].path.length > 3) {
      setStorage({
        ...storage,
        [story._id]: {
          version:story.meta.modificationDate,
          date: new Date().getTime(),
          data,
          stats
        }
      })
    }
  }

  return story ? (
    <StoryPlayer
      ref={playerRef}
      lang={lang}
      story={story}
      showMenu={true}
      onReady={onPlayerReady}
      onSavePoint={onSavePoint}
    />
  ) : null
}

export default App 
