const executeObjectAction = (story, gameData, { target, modifier }) => {
  const asset = story.assets[target] || {}
  let newGameData = null
  let win = false
  let heroShown = null
  let objectShown = null
  let modal = null
  if (asset.kind === 'hero') {
    if (gameData.hero !== target && (modifier === 'toggle' || modifier === 'add')) {
      heroShown = target
      modal = {asset}
      newGameData = {...gameData, hero: target}
      win = true
    } else if (gameData.hero === target && (modifier === 'toggle' || modifier === 'sub')) {
      newGameData = {...gameData, hero: null}
    }
  } else {
    if (!(gameData.objects || []).includes(target) && (modifier === 'toggle' || modifier === 'add')) {
      objectShown = target
      modal = {asset}
      const objects = [...(gameData.objects || []), target]
      newGameData = {...gameData, objects}
      win = true
    } else if ((gameData.objects || []).includes(target) && (modifier === 'toggle' || modifier === 'sub')) {
      newGameData = {...gameData, objects: gameData.objects.filter(x => x !== target)}
    }
  }
  return {
    gameData: newGameData,
    sound: win ? 'objectWin' : 'objectLose',
    heroShown,
    objectShown,
    modal
  }
}

const executeCounterAction = (story, gameData, params) => {
  const {
    target,
    modifier,
    value=0,
    valueType,
    minValueType,
    minValue,
    maxValueType,
    maxValue,
    animation
  } = params
  let modal = null
  const counter = story.counters[target] || {}
  const newCounters = [...(gameData.counters || [])]
  let counterToModify = newCounters.find(x => x.id === target)
  if (!counterToModify) {
    console.log('cannot find counter with id: ', target)
    return null
  }
  let actionValue
  switch (valueType) {
    case 'random': {
      let min, max
      switch (minValueType) {
        case 'counter': {
          const counterMinTarget = newCounters.find(x => x.id === minValue)
          min = counterMinTarget ? counterMinTarget.value : counter.min
          break
        }
        default: {
          min = minValue || counter.min
        }
      }
      switch (maxValueType) {
        case 'counter': {
          const counterMaxTarget = newCounters.find(x => x.id === maxValue)
          max = counterMaxTarget ? counterMaxTarget.value : counter.max
          break
        }
        default: {
          max = maxValue || counter.max
        }
      }
      actionValue = Math.floor(Math.random() * (max - min + 1)) + min;
      modal = animation ? {randomValue: actionValue} : null
      break
    }
    case 'counter': actionValue = (newCounters.find(x => x.id === value).value || 0); break
    default: actionValue = (value || 0)
  }
  if (isNaN(actionValue) || typeof actionValue !== 'number') {
    return null
  }
  let counters = null
  let error = false
  switch (modifier) {
    case 'set': counters = newCounters.map(x => x.id === target ? { id: x.id, value: Math.max(Math.min(actionValue, counter.max), counter.min) } : x); break
    case 'add': counters = newCounters.map(x => x.id === target ? { id: x.id, value: Math.min(x.value + actionValue, counter.max)} : x); break
    case 'sub': counters = newCounters.map(x => x.id === target ? { id: x.id, value: Math.max(x.value - actionValue, counter.min)} : x); break
    default: {
      error = true
    }
  }
  if (!error) {
    const sound = 'counter' + modifier.slice(0,1).toUpperCase() + modifier.slice(1)
    return {
      gameData: {...gameData, counters},
      modal,
      sound
    }
  }
  return null
}

const executeTextvarAction = (story, gameData, { target, modifier, value, valueType }) => {
  const textvar = story.textvars[target] || {}
  const newTextvars = [...(gameData.textvars || [])]
  let textvarToModify = newTextvars.find(x => x.id === target)
  if (!textvarToModify) {
    console.log('cannot find textvar with id: ', target)
    return null
  }
  let foundValue
  switch (valueType) {
    case 'random': {
      const min = 0
      const max = textvar.values ? textvar.values.length : 0
      const index = Math.floor(Math.random() * (max - min + 1)) + min
      foundValue = textvar.values && textvar.values.length > index ? textvar.values[index] : textvar.values[0]
      //modal = {randomValue: actionValue}
      break
    }
    case 'counter': {
      const newCounters = [...(gameData.counters || [])]
      const index = (newCounters.find(x => x.id === value).value || 0)
      foundValue = textvar.values && textvar.values.length > index ? textvar.values[index] : textvar.values[0]
      break
    }
    default: {
      foundValue = textvar.values && textvar.values.find(tv => tv.id === value)
    }
  }
  const actionValue = foundValue ? foundValue.id : textvarToModify.name

  let textvars = null
  let error = false
  switch (modifier) {
    case 'set': textvars = newTextvars.map(x => x.id === target ? { id: x.id, value: actionValue } : x); break
    default: {
      error = true
    }
  }
  if (!error) {
    return {
      gameData: {...gameData, textvars}
    }
  }
  return null
}

const executeRedirectAction = ({ target }) => {
  return { redirectTo: target }
}

export const executeAction = (story, gameData, action) => {
  const { kind, params } = action
  switch (kind) {
    case 'object': return executeObjectAction(story, gameData, params)
    case 'counter': return executeCounterAction(story, gameData, params)
    case 'textvar': return executeTextvarAction(story, gameData, params)
    case 'redirect': return executeRedirectAction(params)
    default: console.log('unknown action ', kind)
  }
  return null
}