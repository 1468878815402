import React, { Fragment, useState, createRef, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { Icon, Image } from 'semantic-ui-react'
import { sequenceAnimations } from '../animations'
import * as easings from 'd3-ease'
import { respectCondition } from '../modifiers/condition-helpers'
import { getSafeTemplate, cleanText } from '../story-player-utils'
import { PlayerLocalesContext } from '../story-player-locales'
import { getCombinedStyles } from '../story-theme-utils'
import { PuzzleRenderer } from './puzzle-renderer'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import './sequence-renderer.css'

const imageButtonContainerStyle = {
  position: 'absolute',
  right: 2,
  top: 2,
  margin: 7,
  zIndex: 1,
  opacity: .8,
  borderRadius: '1em'
}

const imageButtonStyle = {
  padding: '.3em .35em',
  fontSize: '.8em',
  margin: 0
}

const MagnifiedImageWrapped = ({ wrapper, image }) => {

  const [size, setSize] = useState({})

  useEffect(() => {
    if (wrapper) {
      setSize({
        width: wrapper.offsetWidth || '100%',
        height: wrapper.offsetHeight || '100%'
      })
    }
  }, [wrapper])

  return (
    <TransformComponent>
      <div style={{ ...size, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={image} alt='' style={{ width: '100%', height: 'auto' }} />
      </div>
    </TransformComponent>
  )
}

const MagnifiedImage = (props) => {
  const containerRef = createRef()
  const { styles, image, onClose } = props
  return (
    <div className='magnifier-container' ref={containerRef} style={{ position: 'absolute', zIndex: 90, top: 0, left: 0, right: 0, bottom: 0, background: '#fff' }}>
      <div style={{ width: '100%', height: '100%', background: styles && styles.sequenceStyles ? styles.sequenceStyles.background : '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TransformWrapper initialScale={1}>
          {(/*{ zoomIn, zoomOut, resetTransform, ...rest }*/) => (
            <MagnifiedImageWrapped
              wrapper={containerRef ? containerRef.current : null}
              image={image}
            />
          )}
        </TransformWrapper>
        <div style={{...imageButtonContainerStyle, background: styles && styles.sequenceStyles ? styles.sequenceStyles.background : '#000'}}>
          <button style={{...styles.button, ...imageButtonStyle}} onClick={onClose}>
            <Icon name='close' style={{ margin: 0 }} />
          </button>
        </div>
      </div>
    </div>
  )
}

export const SequenceRenderer = (props) => {

  const {
    story,
    gameData,
    path,
    routineExit,
    randomSeeds,
    sequence,
    gotoSequence,
    playClickSound,
    theme,
    styles,
    showStats,
    addReview,
    canAddReview,
    undo,
    canUndo,
    onReplay,
    reducedViewport=false,
    canShowStats
  } = props

  const [tween, setTween] = useState({ opacity: 1, translate: 0 })
  const [sequenceProps, setSequenceProps] = useState(false)
  const [showMagnifiedImage, setShowMagnifiedImage] = useState(null)

  useEffect(() => {
    setShowMagnifiedImage(null)
  }, [sequence, gameData, path])

  const contentRef = createRef()

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [sequence])

  const { opacity, translate } = useSpring({
    config: { duration: 350, easing: easings.easeCubic },
    onRest: () => {
      const {next, actions, conditions} = sequenceProps || {}
      if (tween.immediate) {
        setSequenceProps(null)
        setTween({ opacity: 1, translate: 0, immediate: false})
        gotoSequence(next, actions, conditions)
      } else if (tween && tween.opacity === 0) {
        setTween({ opacity: 0, translate: 100, immediate: true })
      }
    },
    ...tween
  })

  const prepareNextSequence = (sequence) => {
    playClickSound()
    setSequenceProps(sequence)
    setTween({ opacity: 0, translate: -100 })
  }

  const getTweenTransform = (t) => {
    const animationStyle = sequenceAnimations.getAnimationStyle(theme.styles.animations ? theme.styles.animations.sequence : null)
    return animationStyle(t)
  }

  const animatedStyles = {
    opacity,
    transform: translate.interpolate(getTweenTransform),
    ...styles.textStyle
  }

  const filterChoice = (choice) => {
    if (choice.showCondition && choice.showCondition.kind) {
      return respectCondition(story, gameData, path, choice.showCondition)
    }
    return true
  }

  const ChoiceButtons = () => {
    const choices = sequence.choices.filter(c => filterChoice(c))
    return choices.length > 0 ? (
      choices.map((choice, index) => {
        const { button: styleButton } = choice.themeId ? getCombinedStyles(story, choice.themeId) : styles
        return (
          <div key={'choice-' + index}>
            <button style={ styleButton } onClick={() => prepareNextSequence(choice)}><div dangerouslySetInnerHTML={{__html: cleanText(getSafeTemplate(convertVariables(choice.content)))}} /></button>
          </div>
        )
      })
    ) : (
      <button style={ styles.button } onClick={undo}><Icon name='caret left' style={{ margin: 0 }} /></button>
    )
  }

  const onCodeEntered = (code) => {
    const foundCode = sequence.puzzle && sequence.puzzle.codes ? sequence.puzzle.codes.find(x => x.value.toUpperCase() === code.toUpperCase()) : null
    if (foundCode) {
      prepareNextSequence({next: foundCode.next})
    } else if (sequence.puzzle && sequence.puzzle.defaultNext) {
      prepareNextSequence({next: sequence.puzzle.defaultNext})
    } else {
      console.log('No link defined for this code. Check your sequence !')
    }
  }

  const EndButton = ({icon, big, label, ...btProps}) => (
    <button
      style={{
        ...styles.button,
        padding: big ? '.7em 1em' : '.5em 1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '.6em',
        width: reducedViewport ? (big ? '5em' :'6em') : '18vmin',
        minWidth: big ? 70 : 64,
        margin: '0.5em .3em'
      }}
      {...btProps}
    >
      <Icon
        name={icon}
        style={{
          fontSize: big ? '1.8em' : '1.4em',
          margin: '0 0 5px'
        }}
      />  <span style={{fontSize: big ? '1em' : '.8em'}}>{label}</span>
    </button>
  )

  const replayHandler = () => {
    if (onReplay) {
      onReplay()
    } else {
      prepareNextSequence(sequence)
    }
  }

  const HappyEndButtons = ({strings}) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <EndButton
          id="bt-replay"
          icon='repeat'
          disabled={!showStats}
          onClick={replayHandler}
          label={strings.RETRY}
        />
        { (addReview && canAddReview) && (
          <EndButton
            id="bt-rate"
            disabled={!showStats}
            icon='star'
            onClick={addReview}
            label={strings.REVIEWS}
            big={true}
          />
        )}
        { canShowStats && (
          <EndButton
            id="bt-stats"
            icon='chart pie'
            disabled={!showStats}
            onClick={showStats}
            label={strings.STATS}
          />
        )}
      </div>
    )
  }

  const TragicEndButtons = ({strings}) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <EndButton
          id="bt-replay"
          icon='repeat'
          disabled={!showStats}
          onClick={replayHandler}
          label={strings.RETRY}
        />
        { (undo && canUndo) && (
          <EndButton
            id="bt-lastchoice"
            icon='angle double left'
            disabled={!showStats}
            onClick={undo}
            label={strings.BACK}
            big={true}
          />
        )}
        { canShowStats && (
          <EndButton
            id="bt-stats"
            icon='chart pie'
            disabled={!showStats}
            onClick={showStats}
            label={strings.STATS}
          />
        )}
      </div>
    )
  }

  const seqImage = (sequence.image && story.images) ? (story.images[sequence.image.id] || {}) : {}
  const imageUrl = seqImage.urlLocal || seqImage.url
  const decorationImageOpts = {
    src: imageUrl,
    size: sequence.image && sequence.image.size ? sequence.image.size : 'medium',
    circular: false,
    rounded: true
  }

  const illustrationImage = imageUrl && (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ position: 'relative', margin: 'auto' }}>
        <Image style={{ margin: '.5em', maxWidth: 270, maxHeight: sequence.puzzle ? 160 : 'inherit', ...(sequence.puzzle ? {width: 'auto'} : {}) }} {...decorationImageOpts} />
        {(!showMagnifiedImage && sequence.image.allowZoom) && (
          <div style={{...imageButtonContainerStyle, background: styles && styles.sequenceStyles ? styles.sequenceStyles.background : '#000'}}>
            <button
              style={{...styles.button, ...imageButtonStyle}}
              onClick={() => setShowMagnifiedImage(decorationImageOpts.src)}
            >
              <Icon name='zoom-in' fitted />
            </button>
          </div>
        )}
      </div>
    </div>
  )

  const convertVariables = (s) => {
    const regex = /<span class="ql-moikivar"([^<]+)<\/span>/gim
    const regexId = /data-var-id="([A-Za-z0-9-]+)" data-var-op="([a-z]+)"/
    const replacers = []
    const allCounters = gameData.counters || []
    const allTextvars = gameData.textvars || []

    let matches
    let randomizedIndex = 0
    while ((matches = regex.exec(s)) !== null) {
      const foundId = matches[0].match(regexId)
      if (foundId && foundId.length > 1) {
        const counter = allCounters.find(x => x.id === foundId[1]) || story.counters[foundId[1]]
        const textvar = allTextvars.find(x => x.id === foundId[1]) || story.textvars[foundId[1]]
        const op = foundId[2] || 'value'
        let value = ''
        if (counter) {
          switch (op) {
            case 'percent': {
              const cv = counter.value || 0
              const counterDef = story.counters[counter.id]
              value = Math.round(((cv - counterDef.min) / (counterDef.max - counterDef.min)) * 100) + '%'
              break
            }
            default: {
              value = counter.value !== undefined ? counter.value.toString() : counter.defaultValue.toString()
            }
          }
        } else if (textvar) {
          const textvarDef = story.textvars[textvar.id]
          switch (op) {
            case 'random': {
              if (textvarDef.values && textvarDef.values.length > 0) {
                value = textvarDef.values[Math.round(randomSeeds[randomizedIndex] * (textvarDef.values.length-1))].text
                ++randomizedIndex
                if (randomizedIndex > randomSeeds.length) {
                  randomizedIndex = 0
                }
              } else {
                value = textvar.name
              }
              break
            }
            default: {
              value = textvar.name
              if (textvarDef && textvarDef.values && textvarDef.values.length > 0) {
                const foundValue = textvarDef.values.find(x => x.id === textvar.value)
                if (foundValue) {
                  value = foundValue.text
                } else {
                  value = textvarDef.values[0].text
                }
              }
            }
          }
        }
        replacers.push({from: matches[0], to: value})
      }
    }
    let converted = s
    for (let i=0; i<replacers.length; ++i) {
      converted = converted.replace(replacers[i].from, replacers[i].to)
    }
    return converted
  }

  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Fragment>
          <animated.div className="sequence" style={animatedStyles}>
          { sequence && (
              <Fragment>
                { !(sequence.noContent && sequence.puzzle && !sequence.image) && (
                  <div className="sequence-content-wrapper">
                    <div className="sequence-content" style={{ borderRadius: sequence.noContent ? 0 : '1.5em' }}>
                      { !sequence.noContent ? (
                        <div ref={contentRef} style={ styles.sequence }>
                          { (sequence.image && sequence.image.isBefore) && illustrationImage }
                          <div dangerouslySetInnerHTML={{__html: cleanText(getSafeTemplate(convertVariables(sequence.content)))}}/>
                          { (sequence.image && !sequence.image.isBefore) && illustrationImage }
                        </div>
                      ) : (
                        sequence.image && (
                          <div style={{ display: 'flex', justifyContent: 'center', padding: 0, background: 'none', borderRadius: 0 }}>
                            <div style={{ position: 'relative' }}>
                              <Image style={{ width: 'auto', height: 'auto', maxHeight: '100%' }} {...decorationImageOpts} />
                              {(!showMagnifiedImage && sequence.image.allowZoom) && (
                                <div style={{...imageButtonContainerStyle, background: styles && styles.sequenceStyles ? styles.sequenceStyles.background : '#000'}}>
                                  <button
                                    style={{...styles.button, ...imageButtonStyle}}
                                    onClick={() => setShowMagnifiedImage(decorationImageOpts.src)}
                                  >
                                    <Icon name='zoom-in' fitted />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      { (sequence.final && !sequence.hasOwnProperty('routineExitLabel') && sequence.httplink) && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'none' }}>
                          <button style={ styles.button } onClick={() => window.open(sequence.httplink)}>
                            <Icon name='linkify' />
                            {sequence.httplabel}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="sequence-actions" style={ sequence.puzzle ? { flexGrow: 1 } : {}}>
                  { sequence.puzzle ? (
                    <PuzzleRenderer resetCode={tween.opacity === 0} styles={styles} puzzle={sequence.puzzle} onCodeEntered={onCodeEntered} />
                  ) : (
                    <Fragment>
                      { sequence.choices && sequence.choices.length > 0 ? (
                        <ChoiceButtons />
                      ) : (
                        <Fragment>
                          { ((sequence.final || !sequence.next) && sequence.hasOwnProperty('routineExitLabel') && routineExit && routineExit.find(ex => ex.from === sequence.id && !!ex.next)) ? (
                            <div>
                              <button style={ styles.button } onClick={() => prepareNextSequence(sequence)}><Icon name='caret right' style={{ margin: 0 }} /></button>
                            </div>
                          ) : (
                            <Fragment>
                              { (sequence.final || !sequence.next) ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {(sequence.isHappyEnd) ? (
                                    <HappyEndButtons strings={strings} />
                                  ) : (
                                    <TragicEndButtons strings={strings} undo={undo} canUndo={canUndo} />
                                  )}
                                </div>
                              ) : (
                                <button style={ styles.button } onClick={() => prepareNextSequence(sequence)}><Icon name='caret right' style={{ margin: 0 }} /></button>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </animated.div>
          { showMagnifiedImage && (
            <MagnifiedImage
              styles={styles}
              image={showMagnifiedImage}
              onClose={() => setShowMagnifiedImage(null)}
            />
          )}
        </Fragment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}