import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import 'semantic-ui-css/semantic.min.css'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)