export default {
  name: 'standard',
  styles: {
    background: '#4c77ac',
    fontFamily: 'Catamaran',
    topbarBackground: '#154f6b',
    topbarText: '#ffffff',
    sequenceBackground: '#446a9a',
    sequenceText: '#ffffff',
    buttonBackground: '#4eb5f1',
    buttonText: '#ffffff',
    assetsBackground: 'rgba(21, 79, 107, 0.27)',
    assetsFill: '#ffffff',
    sfx: {},
    animations: {
      sequence: "unzoom",
      objectModal: "zoom",
      objectModalDecorator: "star"
    },
    textStyle: {}
  }
}