import React from 'react'
import { Fragment } from 'react'
import { Button, Divider, Modal, Segment } from 'semantic-ui-react'

const ConfirmAction = ({title, content, open, cancelLabel, onCancel, confirmLabel, onConfirm, confirmIcon=null, cancelIcon=null, alternate=false}) => {
  return (
    <Modal
      open={open}
      dimmer='blurring'
      style={{ width: 300, textAlign: 'center' }}
    >
      <Segment inverted>
        <h3>{ title }</h3>
        { content && (
          <Fragment>
            <Divider />
            {content}
          </Fragment>
        )}
        <Divider />
        { alternate ? (
          <div style={{ textAlign: 'center' }}>
            { onConfirm && (
              <Button
                size='big'
                id='modal-validate-button'
                color='blue'
                onClick={ onConfirm }
              >{confirmIcon}{confirmLabel}</Button>
            )}
            {
              onCancel && (
                <div style={{ margin: '1em auto' }}>
                  <button className='button-link' id='modal-cancel-button' onClick={ onCancel }>{cancelIcon}{cancelLabel}</button>
                </div>
              )
            }
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            {
              onCancel && (
                <Button
                  inverted
                  id='modal-cancel-button'
                  onClick={ onCancel }
                  style={{ marginBottom: 5 }}
                >{cancelIcon}{cancelLabel}</Button>
              )
            }
            { onConfirm && (
              <Button
                size='big'
                id='modal-validate-button'
                color='blue'
                onClick={ onConfirm }
              >{confirmIcon}{confirmLabel}</Button>
            )}
          </div>
        )}
      </Segment>
    </Modal>
  )
}

export default ConfirmAction
