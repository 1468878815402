import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import SvgIcon from '../svg-icon'
import { objectModalAnimations } from '../animations'
import { getSafeTemplate } from '../story-player-utils'
import './object-modal.css'
 
const ObjectModal = ({params, theme, onClosed, textStyle}) => {
  const [isOpen, setIsOpen] = useState(false)

  const animationStyle = objectModalAnimations.getAnimationStyle(theme.styles.animations ? theme.styles.animations.objectModal : null)
  const decorator = theme.styles.animations ? theme.styles.animations.objectModalDecorator : null

  const transition = useTransition(isOpen, null, {
    ...animationStyle,
    onRest: () => {
      if (params && !isOpen) {
        onClosed()
      }
    }
  })

  useEffect(() => {
    if (params) {
      setIsOpen(true)
      const timer = setTimeout(() => {
        setIsOpen(false)
      }, (params.asset.duration || 2) * 1000)
      return () => clearTimeout(timer)
    }
  }, [params])

  const skipAnimation = () => {
    setIsOpen(false)
  }
 
  const getIcon = () => {
    let icon = params.asset ? params.asset.icon : null
    return icon ? <SvgIcon className="modal-icon" icon={icon} style={{ fill: theme.styles.sequenceText,  stroke: theme.styles.sequenceText }} /> : null
  }

  return params && (
    <div className="object-modal-container" style={{ fontFamily: theme.styles.fontFamily, ...textStyle }} onClick={skipAnimation}>
      {transition.map(
        ({ item, key, props }) => (
          item && (
            <animated.div key={key} style={props} className="object-modal-wrapper">
              <div className="object-modal" style={{ background: theme.styles.sequenceBackground }}>
                { getIcon() }
                { params.asset && params.asset.desc && (
                  <div className="object-modal-label" style={{ marginTop: '.5em', color: theme.styles.sequenceText }} dangerouslySetInnerHTML={{__html: getSafeTemplate(params.asset.desc)}} />
                )}
              </div>
            </animated.div>
          )
        )
      )}
      { decorator === "star" && (
        <div className="object-modal-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="699.936" height="699.936" viewBox="0 0 699.936 699.936">
            <path 
              opacity=".7"
              fill={theme.styles.animations.objectModalDecoratorColor || '#FCEE21'}
              d="M349.968 199.968L404.72 4.277l-8.4 203.032L508.864 38.115l-70.728 190.5L597.455 102.48 471.32 261.8l190.5-70.729-169.194 112.544 203.033-8.399-195.691 54.752 195.691 54.752-203.033-8.4 169.193 112.544-190.499-70.728 126.135 159.319L438.136 471.32l70.728 190.5L396.32 492.626l8.4 203.033-54.752-195.691-54.752 195.691 8.399-203.033L191.071 661.82l70.729-190.5-159.32 126.135 126.135-159.319-190.5 70.728L207.309 396.32l-203.032 8.4 195.691-54.752L4.277 295.216l203.032 8.399L38.116 191.071 228.615 261.8 102.48 102.48 261.8 228.615l-70.729-190.5 112.544 169.194-8.399-203.032z"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default ObjectModal