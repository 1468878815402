import * as moikiBaseThemes from './themes'
import * as textEffects from './text-effects'
export const moikiThemes = Object.entries(moikiBaseThemes).map(([_, th]) => th)

export const moikiDefaultTheme = moikiBaseThemes.standard

export const getCombinedStyles = (story, themeId) => {
  const theme = story.themes.find(th => th.identifier === themeId) || {}
  const baseTheme = moikiThemes.find(th => th.name === theme.name) || moikiBaseThemes.standard
  const storyStyles = theme.styles ? theme.styles : {}
  const sequenceStyles = {...baseTheme.styles, ...storyStyles}
  const sequenceTextStyle = textEffects.getTextStyle(sequenceStyles.textStyle)
  return {
    sequenceStyles,
    textStyle: sequenceTextStyle,
    sequence: {
      background: sequenceStyles.sequenceBackground,
      color: sequenceStyles.sequenceText,
      fontFamily: sequenceStyles.fontFamily
    },
    button: {
      background: sequenceStyles.buttonBackground,
      color: sequenceStyles.buttonText,
      fontFamily: sequenceStyles.fontFamily,
      ...sequenceTextStyle
    }
  }
}