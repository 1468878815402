import React, { Fragment } from 'react'
import { Segment, Button, Loader, Divider, Image } from 'semantic-ui-react'
import SvgIcon from './svg-icon'
import StoryModalHeader from './story-modal-header'
import { PlayerLocalesContext } from './story-player-locales'
import { cutText } from './utils'
import PlayerVersion from './player-version'

const StoryStartScreen = (props) => {
  const {
    story,
    onClose
  } = props

  const getLicense = (kind) => {
    if (kind.indexOf('/by-nc/') !== -1) {
      return 'BY-NC'
    } else if (kind.indexOf('/zero/') !== -1) {
      return 'CC'
    } else if (kind.indexOf('/by/') !== -1 || kind === 'creative-commons-attribution') {
      return 'BY'
    } else if (kind === 'public-domain') {
      return 'public'
    } else {
      return '???'
    }
  }

  const soundHooks = window.story_hooks ? window.story_hooks.sounds : {}

  const images = Object.entries(story.images).map(([_, s]) => s)

  const assetsArray = Object.entries(story.assets).map(([_, s]) => s)
  const assets = assetsArray.filter(({info}) => info && info.license).map(({icon, info}) => ({
    icon,
    attribution: info.attribution,
    license: getLicense(info.license)
  }))

  const soundsArray = Object.entries(story.sounds).map(([_, s]) => s)
  const sounds = soundsArray.filter(({sound}) => sound.username && sound.name && sound.license).map(({sound}) => ({
    name: sound.name,
    username: sound.username,
    license: getLicense(sound.license),
    file: sound.localFile.slice(sound.localFile.indexOf('/') + 1)
  }))

  const hasCoverImage = story.meta.imageInfo && (story.meta.imageInfo.user || story.meta.imageInfo.ikFileId)

  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Segment inverted padded='very' style={{ width: 350, padding: '3em 1em'}}>
          <Button basic inverted circular icon='arrow left' onClick={onClose} style={{ position: 'absolute', top: 10, left: 10 }} />
          { story ? (
            <Fragment>
              <StoryModalHeader story={story} />
              <Divider style={{ marginTop: '2.5em' }} inverted horizontal>{strings.CREDITS}</Divider>
              <div style={{ minHeight: 130, maxHeight: 192, overflowY: 'auto' }}>
                { (story.meta.customCredits && (story.meta.customCredits.content || story.meta.customCredits.link)) && (
                  <div>
                    { story.meta.customCredits.content && (
                      <div>{ cutText(story.meta.customCredits.content, 260, true) }</div>
                    )}
                    <div style={{ margin: '.5em' }}>
                      { story.meta.customCredits.link && (
                        <a target="_blank" rel="noreferrer noopener" href={story.meta.customCredits.link}>
                          {
                            story.meta.customCredits.imgLink ? (
                              <img
                                style={{maxWidth: 120, overflow: 'hidden', width: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}
                                src={story.meta.customCredits.imgLink}
                                alt=''
                                className='cover'
                              />
                            ) : strings.READ_MORE
                          }
                        </a>
                      )}
                    </div>
                    <div style={{ display: 'block', height: 1, background: 'rgba(255, 255, 255, 0.15)' }}></div>
                  </div>
                )}
                { hasCoverImage && (
                  <Fragment>
                    <h4>{strings.COVER}</h4>
                    <Image style={{ margin: '.4em auto' }} src={story.meta.imageLocal || story.meta.image} size='small' rounded />
                    { (story.meta.imageInfo && story.meta.imageInfo.user) ? (
                      <div>
                        by <a href={story.meta.imageInfo.hotlink} target="_blank" rel="noreferrer noopener">{story.meta.imageInfo.user}</a>
                        <div style={{ fontStyle: 'italic', color: '#9e9a9a' }}>(<a target="_blank" rel="noreferrer noopener" href="https://unsplash.com/?utm_source=Moiki&utm_medium=moiki.fr">Unsplash</a>)</div>
                      </div>
                    ) : (
                      <div style={{ fontStyle: 'italic', color: '#9e9a9a' }}>
                        { (story.meta.imageInfo && story.meta.imageInfo.ikFileId) ? (
                          <div>{story.meta.imageInfo.customCredit || strings.ALL_RIGHTS_RESERVED}</div>
                        ) : (
                          <a target="_blank" rel="noreferrer noopener" href="https://unsplash.com/?utm_source=Moiki&utm_medium=moiki.fr">Unsplash</a>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
                { (images && images.length > 0) && (
                  <div className='credits-assets-list'>
                    { hasCoverImage && (<Divider />)}
                    <h4 style={{ marginTop: '1em' }}>{strings.IMAGES}</h4>
                    <div style={{ fontStyle: 'italic', color: '#9e9a9a' }}>
                      { images && images.map(img => (
                        <div style={{ marginBottom: '1em' }}>
                          <Image style={{ margin: '.4em auto' }} src={img.urlLocal || img.url} size='tiny' rounded />
                          <div>{img.imageInfo.customCredit || strings.ALL_RIGHTS_RESERVED}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                { assetsArray && assetsArray.length > 0 && (
                  <div className='credits-assets-list'>
                    { hasCoverImage && (<Divider />)}
                    <h4 style={{ marginTop: '1em' }}>{strings.ICONS}</h4>
                    <div style={{ fontStyle: 'italic', marginBottom: '1em', color: '#9e9a9a' }}>{strings.ICONS_FROM}<br/><a target="_blank" rel="noreferrer noopener" href="https://thenounproject.com/">The Noun Project</a></div>
                    { assets && assets.map(({icon, attribution, license}, idx) => (
                      <Fragment key={ 'icon-credit-' + idx }>
                        { idx > 0 && (<div>---</div>)}
                        <SvgIcon
                          icon={icon}
                          style={{ fill: '#fff', stroke: '#fff', margin: 0, paddingBottom: '.5em', paddingTop: '1em', display: 'inline-block' }}
                        />
                        { attribution && <div style={{ overflowWrap: 'break-word' }}>{attribution}</div> }
                        <div style={{ fontSize: '.7em' }}>{strings.LICENSE} {license}</div>
                      </Fragment>
                    ))}
                  </div>
                )}
                { soundsArray && soundsArray.length > 0 && (
                  <Fragment>
                    <Divider />
                    <h4 style={{ marginTop: '1em' }}>{strings.SOUNDS}</h4>
                    { sounds && sounds.map(({name, username, license, file}, idx) => (
                      <Fragment key={ 'sound-credit-' + idx }>
                        { idx > 0 && (<div>---</div>)}
                        { file && soundHooks[file] ? (
                          <Fragment>
                            <div><em style={{ overflowWrap: 'break-word' }}>{soundHooks[file].credit || name}</em></div>
                            { soundHooks[file].license && (
                              <div style={{ color: '#9e9a9a', margin: 2 }}>{strings.LICENSE} {soundHooks[file].license}</div>  
                            )}
                            { soundHooks[file].link && soundHooks[file].linkName && (
                              <div>via <a target="_blank" rel="noreferrer noopener" href={soundHooks[file].link}>{soundHooks[file].linkName}</a></div>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div><em style={{ overflowWrap: 'break-word' }}>{name}</em> by {username}</div>
                            <div style={{ color: '#9e9a9a', margin: 2 }}>{strings.LICENSE} {license}</div>
                            <div>via <a target="_blank" rel="noreferrer noopener" href="https://freesound.org/">Freesound</a></div>
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                )}
              </div>
            </Fragment>
          ) : (
            <Loader active={true} />
          )}
          <PlayerVersion />
        </Segment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default StoryStartScreen