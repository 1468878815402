import { createContext } from 'react'

export const locales = {
  fr: {
    'PRESENT': 'Moiki présente',
    'STORY_BY': 'Une histoire de',
    'ADAPTED_BY': 'adaptation Moiki par',
    'CREDITS': 'Crédits',
    'COVER': 'Image de couverture',
    'IMAGES': 'Images / Photos',
    'ALL_RIGHTS_RESERVED': 'Droits réservés',
    'ICONS': 'Icônes',
    'ICONS_FROM': 'Toutes les icônes proviennent de',
    'LICENSE': 'licence:',
    'SOUNDS': 'Sons',
    'SOUNDS_FROM': 'Tous les sons proviennent de',
    'PROGRESSION': 'Progression',
    'SEQ_FOUND': 'Séquences découvertes :',
    'HEROES_FOUND': 'Héros obtenus :',
    'OBJ_FOUND': 'Objets découverts :',
    'STATS': 'Stats',
    'SHOW_STATS': 'voir les stats',
    'NUM_UNDO': 'Retours en arrière :',
    'NUM_GAMES': 'Parties commencées :',
    'NUM_VICTORIES': 'Nombre de victoires :',
    'NUM_DEFEATS': 'Nombre de défaites :',
    'RESTART': 'Recommencer',
    'RESET': 'Réinitialiser et recommencer',
    'RETRY': 'Réessayer',
    'REPORT': 'signaler ce contenu',
    'REPORT_THIS': 'Signaler',
    'SHOW_CREDITS': 'voir les crédits',
    'REPORT_SENT': 'Signalement envoyé !',
    'RETRY_REASON': 'Je souhaite signaler cette histoire pour la raison suivante\u00a0:',
    'OFFENSIVE': 'Caractère offensant',
    'DUPLICATA': 'Contenu dupliqué (plagiat)',
    'OTHER_REASON': 'Autre raison',
    'USEFUL_INFO': 'Informations utiles',
    'SEND': 'Envoyer',
    'LOADING_SOUNDS': 'téléchargement des sons',
    'OBJ_WON': 'Objets remportés',
    'NO_OBJ_WON': 'Vous n\'avez gagné aucun objet',
    'READ_MORE': 'En savoir plus',
    'REVIEWS': 'Avis',
    'SHARE': 'Partager',
    'BACK': 'Retour',
    'UNKNOWN': 'Inconnu',
    'RESUME': 'Reprendre ?',
    'RESUME_MSG': 'Voulez-vous reprendre votre partie en cours\u00a0?',
    'RESUME_DATE': 'sauvegardée il y a %s',
    'RESUME_ALERT': 'En choisissant de commencer une nouvelle partie, votre sauvegarde sera perdue\u00a0!',
    'DO_NOT_RESUME': 'Recommencer depuis le début',
    'LOAD': 'Reprendre la partie',
    'RESET_GAME': 'Réinitialiser',
    'RESET_MSG': 'En réinitialisant le jeu, vous repartirez depuis le début et vos statistiques seront remises à zéro.',
    'RESTART_MSG': 'En recommençant le jeu, vous repartirez depuis le début en conservant vos statistiques.',
    'WILL_LOSE_BACKUP': 'Attention, votre sauvegarde sera perdue\u00a0!',
    'CANCEL': 'Annuler',
    'ITS_FREE': 'est une plateforme gratuite permettant de créer des histoires à embranchements.'
  },
  en: {
    'PRESENT': 'Moiki presents',
    'STORY_BY': 'A story by',
    'ADAPTED_BY': 'Moiki adaptation by',
    'CREDITS': 'Credits',
    'COVER': 'Cover picture',
    'IMAGES': 'Images / Pictures',
    'ALL_RIGHTS_RESERVED': 'All rights reserved',
    'ICONS': 'Icons',
    'ICONS_FROM': 'All icons come from',
    'LICENSE': 'license:',
    'SOUNDS': 'Sounds',
    'SOUNDS_FROM': 'All sounds come from',
    'PROGRESSION': 'Progression',
    'SEQ_FOUND': 'Discovered sequences:',
    'HEROES_FOUND': 'Heroes obtained:',
    'OBJ_FOUND': 'Objects discovered:',
    'STATS': 'Stats',
    'SHOW_STATS': 'see stats',
    'NUM_UNDO': 'Backwards:',
    'NUM_GAMES': 'Started games:',
    'NUM_VICTORIES': 'Number of wins:',
    'NUM_DEFEATS': 'Number of defeats:',
    'RESTART': 'Start again',
    'RESET': 'Reset and restart',
    'RETRY': 'Retry',
    'REPORT': 'report this content',
    'REPORT_THIS': 'Report',
    'SHOW_CREDITS': 'see credits',
    'REPORT_SENT': 'Report sent!',
    'REPORT_REASON': 'I would like to report this story for the following reason:',
    'OFFENSIVE': 'Offensive nature',
    'DUPLICATA': 'Duplicate content (plagiarism)',
    'OTHER_REASON': 'Other reason',
    'USEFUL_INFO': 'Useful information',
    'SEND': 'Send',
    'LOADING_SOUNDS': 'downloading sounds',
    'OBJ_WON': 'Won objects',
    'NO_OBJ_WON': 'You have not won any items',
    'READ_MORE': 'Read more',
    'REVIEWS': 'Reviews',
    'SHARE': 'Share',
    'BACK': 'Back',
    'UNKNOWN': 'Unknown',
    'RESUME': 'Resume?',
    'RESUME_MSG': 'Do you want to resume your game in progress?',
    'RESUME_DATE': 'saved %s ago',
    'RESUME_ALERT': 'By choosing to start a new game, your backup will be lost!',
    'DO_NOT_RESUME': 'Start a new game',
    'LOAD': 'Resume the game',
    'RESET_GAME': 'Reset',
    'RESET_MSG': 'By resetting the game, you will start from the beginning and your statistics will be reset to zero.',
    'RESTART_MSG': 'By restarting the game, you will start again from the beginning by keeping your statistics.',
    'WILL_LOSE_BACKUP': 'Be careful, your backup will be lost!',
    'CANCEL': 'Cancel',
    'ITS_FREE': 'is a free platform to create branching stories.'
  }
}

export const PlayerLocalesContext = createContext(locales.fr)