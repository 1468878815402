import React, { Fragment } from 'react'
import { Segment, Button, Divider } from 'semantic-ui-react'
import SvgIcon from './svg-icon'
import { PlayerLocalesContext } from './story-player-locales'
import './win-objects-panel.css'
import { stripTags } from './story-player-utils'

const WinObjectsPanel = ({onClose, objects, getAssetById, styles}) => {
  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Segment inverted padded='very' style={{ width: 350}}>
          <Button primary circular icon='close' onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }} />
          { objects && (<Divider inverted horizontal style={{ marginTop: 0 }}>{strings.OBJ_WON} ({objects.length})</Divider>)}
          <div className='win-objects-panel'>
            {objects && objects.length > 0 ? (
              <div className="list-objects-container">
                {objects && objects.map((obj, idx) => {
                  const asset = getAssetById(obj)
                  return (
                    <Fragment key={`obj-renderer-${idx}`}>
                      {idx > 0 && (
                        <Divider />
                      )}
                      <div style={{ marginBottom: '1em' }}>
                        <div style={{color: '#9e9a9a', fontWeight: 'bold', fontSize: 20}}>{asset.label.trim()}</div>
                        <SvgIcon
                          icon={asset.icon}
                          style={{ fill: '#fff', stroke: '#fff', margin: 0, paddingBottom: '.5em', display: 'inline-block' }}
                        />
                        <div style={{color: '#fff'}}>{stripTags(asset.desc).trim()}</div>
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <p>{strings.NO_OBJ_WON}</p>
            )}
          </div>
        </Segment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default WinObjectsPanel