import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Icon } from 'semantic-ui-react'

const CodeRenderer = ({value, size=4, styles={}}) => {
  const chars = Array.from({length: size})
  return chars.map((c, idx) => (
    <div key={'charcode-' + idx} className="sequence-content" style={{ margin: 2, width: '2em', height: '2em', overflow: 'hidden' }}>
      <div style={{ ...styles.sequence, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        { value.slice(idx, idx + 1) || '_' }
      </div>
    </div>
  ))
}

export const PuzzleRenderer = (props) => {
  const {
    resetCode,
    puzzle,
    styles,
    onCodeEntered,
  } = props

  const [canValidate, setCanValidate] = useState(false)
  const [canErase, setCanErase] = useState(false)
  const [userCode, setUserCode] = useState('')

  const addCharToCode = (char) => {
    setUserCode((userCode + char).slice(0, puzzle.size))
  }

  const delCharFromCode = () => {
    setUserCode(userCode.slice(0, -1))
  }

  useEffect(() => {
    setCanValidate(userCode.length === puzzle.size)
    setCanErase(userCode.length > 0)
  }, [userCode])

  useEffect(() => {
    if (!resetCode) {
      setUserCode('')
      setCanErase(false)
      setCanValidate(false)
    }
  }, [resetCode])

  const onValidateCode = () => {
    onCodeEntered && onCodeEntered(userCode)
  }

  const keys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

  return (
    <div className="puzzle-renderer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, flexGrow: 1 }}>
        <CodeRenderer value={userCode} size={puzzle.size} styles={styles} />
      </div>
      { puzzle.kind === 'numeric' && (
        <Fragment>
          <div className="pad-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button style={ styles.button } onClick={() => addCharToCode('1')}>1</button>
            <button style={ styles.button } onClick={() => addCharToCode('2')}>2</button>
            <button style={ styles.button } onClick={() => addCharToCode('3')}>3</button>
          </div>
          <div className="pad-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button style={ styles.button } onClick={() => addCharToCode('4')}>4</button>
            <button style={ styles.button } onClick={() => addCharToCode('5')}>5</button>
            <button style={ styles.button } onClick={() => addCharToCode('6')}>6</button>
          </div>
          <div className="pad-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button style={ styles.button } onClick={() => addCharToCode('7')}>7</button>
            <button style={ styles.button } onClick={() => addCharToCode('8')}>8</button>
            <button style={ styles.button } onClick={() => addCharToCode('9')}>9</button>
          </div>
          <div className="pad-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button disabled={!canErase} style={{...styles.button, opacity: canErase ? 1 : .5 }} onClick={() => setUserCode('')}>
              <Icon name='close' style={{ margin: 0, display: 'flex', width: 10.5, justifyContent: 'center' }} />
            </button>
            <button style={ styles.button } onClick={() => addCharToCode('0')}>0</button>
            <button disabled={!canErase} style={{...styles.button, opacity: canErase ? 1 : .5 }} onClick={() => delCharFromCode()}>
              <Icon name='angle left' style={{ margin: 0, display: 'flex', width: 10.5, justifyContent: 'center' }} />
            </button>
          </div>
        </Fragment>
      )}
      { puzzle.kind === 'alpha' && (
        <Fragment>
          { [0,1,2,3].map((idx) => (
            <div key={'alphakeys-' + idx} className="pad-buttons small" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              { keys && keys.slice(idx * 6, (idx * 6) + 6).map((key) => (
                <button key={'alphakey-' + key} style={ styles.button } onClick={() => addCharToCode(key)}>{key}</button>
              ))}
            </div>
          ))}
          <div className="pad-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button disabled={!canErase} style={{...styles.button, opacity: canErase ? 1 : .5 }} onClick={() => setUserCode('')}>
              <Icon name='trash' style={{ margin: 0, display: 'flex', width: 10.5, justifyContent: 'center' }} />
            </button>
            <button style={{...styles.button, width: 46}} onClick={() => addCharToCode(keys.slice(-2)[0])}>{keys.slice(-2)[0]}</button>
            <button style={{...styles.button, width: 46}} onClick={() => addCharToCode(keys.slice(-1)[0])}>{keys.slice(-1)[0]}</button>
            <button disabled={!canErase} style={{...styles.button, opacity: canErase ? 1 : .5 }} onClick={() => delCharFromCode()}>
              <Icon name='angle left' style={{ margin: 0, display: 'flex', width: 10.5, justifyContent: 'center' }} />
            </button>
          </div>
        </Fragment>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
        <button disabled={!canValidate} style={{...styles.button, opacity: canValidate ? 1 : .5 }} onClick={onValidateCode}>
          <Icon name='caret right' style={{ margin: 0 }} />
        </button>
      </div>
    </div>
  )
}