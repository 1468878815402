export default {
  name: 'old-screen',
  styles: {
    background: 'rgba(0, 0, 0, 1)',
    fontFamily: 'Ubuntu',
    topbarBackground : 'rgba(0, 0, 0, 1)',
    topbarText : 'rgba(110, 169, 37, 1)',
    sequenceBackground : 'rgba(0, 0, 0, 1)',
    sequenceText : 'rgba(184, 233, 134, 1)',
    buttonBackground : 'rgba(184, 233, 134, 1)',
    buttonText : 'rgba(0, 0, 0, 1)',
    assetsBackground : 'rgba(87, 126, 48, 0.59)',
    assetsFill : 'rgba(209, 236, 182, 1)',
    sfx: {},
    animations: {
        sequence: 'fade',
        objectModal: 'fade'
    },
    textStyle: {
        type : 'glow',
        params : {
            size : 8,
            color2 : 'rgba(126, 211, 33, 1)',
            color : 'rgba(65, 117, 5, 1)'
        }
    }
  }
}