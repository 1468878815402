export default {
  name: 'dark',
  styles: {
    background: 'rgba(0, 0, 0, 1)',
    fontFamily: 'Londrina Solid',
    buttonBackground: 'rgba(255, 255, 255, 0.18)',
    sequenceBackground: 'rgba(0, 0, 0, 0.8)',
    assetsBackground: 'rgba(0, 0, 0, 0.76)',
    sequenceText: 'rgba(255, 255, 255, 1)',
    topbarBackground: 'rgba(22, 31, 31, 1)',
    topbarText: 'rgba(131, 130, 130, 1)',
    assetsFill: 'rgba(255, 255, 255, 1)',
    sfx: {},
    animations: {
      sequence: "fade",
      objectModal: "rotateZoom",
      objectModalDecorator: "star"
    },
    textStyle: {}
  }
}