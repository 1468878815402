const executeObjectCondition = (gameData, params, story) => {
  const {target, condition} = params
  const { kind } = (story.assets[target] || {})
  if (kind === 'hero') {
    switch (condition) {
      case 'with': return gameData.hero === target
      case 'without': return gameData.hero !== target
      default: console.log('unknown condition: ', condition)
    }
  } else {
    const obj = gameData.objects || []
    switch (condition) {
      case 'with': return obj.includes(target)
      case 'without': return !obj.includes(target)
      default: console.log('unknown condition: ', condition)
    }
  }
  return null
}

const executeCounterCondition = (story, gameData, params) => {
  const {
    target,
    condition,
    value=0,
    valueType,
    minValueType,
    minValue,
    maxValueType,
    maxValue
  } = params
  const storyCounter = story.counters[target] || {}
  const allCounters = gameData.counters || []
  const counter = allCounters.find(x => x.id === target)
  let conditionValue
  switch (valueType) {
    case 'random': {
      let min, max
      switch (minValueType) {
        case 'counter': {
          const counterMinTarget = allCounters.find(x => x.id === minValue)
          min = counterMinTarget ? counterMinTarget.value : storyCounter.min
          break
        }
        default: {
          min = minValue || storyCounter.min
        }
      }
      switch (maxValueType) {
        case 'counter': {
          const counterMaxTarget = allCounters.find(x => x.id === maxValue)
          max = counterMaxTarget ? counterMaxTarget.value : storyCounter.max
          break
        }
        default: {
          max = maxValue || storyCounter.max
        }
      }
      conditionValue = Math.floor(Math.random() * (max - min + 1)) + min
      break
    }
    case 'counter': conditionValue = (allCounters.find(x => x.id === value).value || 0); break
    default: conditionValue = value
  }
  if (counter) {
    switch (condition) {
      case '=': return counter.value === conditionValue
      case '!=': return counter.value !== conditionValue
      case '>': return counter.value > conditionValue
      case '>=': return counter.value >= conditionValue
      case '<': return counter.value < conditionValue
      case '<=': return counter.value <= conditionValue
      default: console.log('unknown condition: ', condition)
    }
  }
  return null
}

const executeTextvarCondition = (gameData, params) => {
  const {target, condition, value} = params
  const allTextvars = gameData.textvars || []
  const textvar = allTextvars.find(x => x.id === target)
  if (textvar) {
    switch (condition) {
      case '=': return textvar.value === value
      case '!=': return textvar.value !== value
      default: console.log('unknown condition: ', condition)
    }
  }
  return null
}

const executePassageCondition = (path, params) => {
  const {target, condition} = params
  const passages = path || []
  switch (condition) {
    case 'by': return passages.includes(target)
    case 'not-by': return !passages.includes(target)
    default: console.log('unknown condition: ', condition)
  }
  return null
}

const executeMultiCondition = (story, gameData, path, {operator, params}) => {
  const shouldCumulate = operator === 'and'
  let result = shouldCumulate && params.length > 0
  let isRespected = false
  for (let param of (params || [])) {
    switch (param.kind) {
      case 'object': isRespected = executeObjectCondition(gameData, param, story); break
      case 'counter': isRespected = executeCounterCondition(story, gameData, param); break
      case 'textvar': isRespected = executeTextvarCondition(gameData, param); break
      case 'passage': isRespected = executePassageCondition(path, param); break
      default: {
        console.log('unknown multi-condition item kind', param.kind)
      }
    }
    result = shouldCumulate ? (result && isRespected) : (result || isRespected)
  }
  return result
}

export const respectCondition = (story, gameData, path, condition) => {
  const {kind, query} = condition
  const [firstParam={}] = (query.params || [])
  switch (kind) {
    case 'object': return executeObjectCondition(gameData, firstParam, story)
    case 'counter': return executeCounterCondition(story, gameData, firstParam)
    case 'textvar': return executeTextvarCondition(gameData, firstParam)
    case 'passage': return executePassageCondition(path, firstParam)
    case 'multiple': return executeMultiCondition(story, gameData, path, query)
    default: {
      kind && console.log('unknown condition kind: ', kind)
      return false
    }
  }
}

export const executeConditions = (story, gameData, path, conditions) => {
  let conditionNext = null
  if (conditions && conditions.length > 0) {
    let condIndex = 0
    while (condIndex < conditions.length && !conditionNext) {
      const thisCondition = conditions[condIndex]
      if (respectCondition(story, gameData, path, thisCondition)) {
        conditionNext = thisCondition.next
      }
      ++condIndex
    }
  }
  return conditionNext
}