export const getAuthor = (story) => {
  if (story && story.author) {
    const { firstname, lastname, pseudo, socialClubProfile } = story.author
    if (pseudo) {
      return pseudo
    } else if (firstname && lastname) {
      return firstname + ' ' + lastname
    } else if (socialClubProfile && socialClubProfile.slug) {
      return '@' + socialClubProfile.slug
    }    
  }
  return 'Inconnu'
}

export const cutText = (s, len=260, cutOnWords=false) => {
  if (s && s.length > len) {
    if (cutOnWords) {
      let tmp = s.slice(0, len)
      tmp = tmp.slice(0, tmp.lastIndexOf(' '))
      return tmp + '...'
    } else {
      return s.substring(0, len) + '...'  
    }
  }
  return s
}