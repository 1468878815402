import React from 'react'
import { version } from '../../package.json'

const year = new Date().getFullYear()

const PlayerVersion = () => {
  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      fontStyle: 'italic',
      fontSize: '.8em',
      color: '#999',
      bottom: 10,
      left: 0
    }}>Moiki © {year} - v.{version}</div>
  )
}

export default PlayerVersion