import React, { Fragment, useEffect } from 'react'
import { Segment, Button, Loader, Divider } from 'semantic-ui-react'
import { cutText } from './utils'
import StoryModalHeader from './story-modal-header'
import { PlayerLocalesContext } from './story-player-locales'
import { unsecableRawSpaces } from './story-player-utils'
import PlayerVersion from './player-version'

const StoryStartScreen = (props) => {
  const {
    story,
    soundsLoaded,
    soundsProgress,
    onStart,
    onShowCredits
  } = props
  const hasSounds = story.sounds && Object.keys(story.sounds).length > 0
  let soundsAvailable = true
  if (navigator && navigator.userAgent && (navigator.userAgent.indexOf('Nintendo') !== -1 || navigator.userAgent.indexOf('Tesla') !== -1)) {
    soundsAvailable = false
  }
  const isLoaded = !soundsAvailable || soundsLoaded || !hasSounds || (soundsProgress || 0).toFixed(0) === 100

  useEffect(() => {
    if (story) {
      const images = Object.entries(story.images).map(([_, s]) => s)
      for (let img of images) {
        const newImage = new Image()
        newImage.src = img.urlLocal || img.url
      }
    }
  }, [story])

  return (
    <PlayerLocalesContext.Consumer>
      {strings => (
        <Segment id="story-start-screen" inverted padded='very' style={{ width: 350, padding: '2.5em 3em' }}>
          { story ? (
            <Fragment>
              <StoryModalHeader story={story} showIfid={false} />
              { (story.meta.imageLocal || story.meta.image) && (
                <div>
                  <img style={{maxHeight: 225, overflow: 'hidden', width: '100%'}} src={story.meta.imageLocal || story.meta.image} alt='' className='cover' />
                </div>
              )}
              <p style={{ marginTop: '0.3em' }}>{ cutText(unsecableRawSpaces(story.meta.description), 260, true) }</p>
              <div style={{ height: '3em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isLoaded ? (
                  <Button id="play-button" onClick={() => onStart({sounds: true})} primary icon='play' circular size='huge' style={{ margin: '.75em' }} />
                ) : (
                  <Segment inverted style={{ margin: '1em', color: '#9e9a9a' }}>
                    <div>{strings.LOADING_SOUNDS}<br/>{ soundsProgress.toFixed(0) }%</div>
                    <div style={{ width: '100%', height: 4, position: 'relative', background: '#666', display: 'block', borderRadius: '2px', overflow: 'hidden' }}>
                      <div style={{ height: '100%', width: soundsProgress + '%', background: '#2185D0', display: 'block'  }} />
                    </div>
                  </Segment>
                )}
              </div>
              <Divider inverted />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button id="credits-button" className='link-button' basic inverted size='mini' onClick={onShowCredits}>{strings.SHOW_CREDITS}</Button>
              </div>
            </Fragment>
          ) : (
            <Loader active={true} />
          )}
          <PlayerVersion />
        </Segment>
      )}
    </PlayerLocalesContext.Consumer>
  )
}

export default StoryStartScreen