import { v4 as uuidv4 } from 'uuid'
import { sanitize } from 'dompurify'

export const unsecableRawSpaces = (str) => str.replace(/\s([?!:»])/g, '\u00a0$1').replace(/(«)\s/g, '$1\u00a0')

export const unsecableSpaces = (str) => str.replace(/\s([?!:»])/g, '&nbsp;$1').replace(/(«)\s/g, '$1&nbsp;')

export const getSafeTemplate = (reactElement) => unsecableSpaces(sanitize(reactElement))

export const cleanText = (text) => text.replace(/(\s*&nbsp;\s*)+/g, '<span class="unbreak-spaces">&nbsp;</span>')

export const stripTags = (s) => s
  .replace(/<span class="ql-moikivar"([^>]+)>([^<]+)<\/span>/gim, '{$2}')
  .replace(/(<([^>]+)>)/gi, '')
  .replace(/&nbsp;/gi, ' ')
  .replace(/\u200c/gi, ' ')
  .replace(/([?.:!]{1})(\w)/gi, '$1 $2')
  .replace(/\.\s\.\./gi, '...').trim()

export const convertRoutines = (story) => {
  // put routines in sequences, updating all ids...
  const updateCondition = (cond, prefixId) => {
    if (cond.next) {
      cond.next = prefixId + ':' + cond.next
    }
    if (cond.kind === 'passage' && cond.query.params && cond.query.params.length > 0) {
      if (cond.query.params[0].target) {
        cond.query.params[0].target = prefixId + ':' + cond.query.params[0].target
      }
    } else if (cond.kind === 'multiple' && cond.query.params && cond.query.params.length > 0) {
      for (let p of cond.query.params || []) {
        if (p.kind === 'passage' && p.target) {
          p.target = prefixId + ':' + p.target
        }
      }
    }
  }

  const updateModifiers = (obj, prefixId) => {
    for (let cond of obj.conditions || []) {
      updateCondition(cond, prefixId)
    }
  }

  const allSequences = [{id: uuidv4(), sequences: story.sequences}, ...story.routines]
  let rId = 0
  for (let r of allSequences) {
    for (let rSeq of r.sequences) {
      rSeq.id = rId + ':' + rSeq.id
      updateModifiers(rSeq, rId)
      for (let ch of (rSeq.choices || [])) {
        updateModifiers(ch, rId)
        if (ch.next) {
          ch.next = rId + ':' + ch.next
        }
        if (ch.showCondition) {
          updateCondition(ch.showCondition, rId)
        }
      }
      if (rSeq.puzzle) {
        if (rSeq.puzzle.defaultNext) {
          rSeq.puzzle.defaultNext = rId + ':' + rSeq.puzzle.defaultNext
        }
        for (let code of (rSeq.puzzle.codes || [])) {
          if (code.next) {
            code.next = rId + ':' + code.next
          }
        }
      }
      if (rSeq.routineId) {
        const routineIdx = allSequences.findIndex(x => x.id === rSeq.routineId)
        rSeq.redirectTo = routineIdx + ':' + allSequences[routineIdx].firstSequence
        for (let exit of (rSeq.routineExit || [])) {
          exit.from = exit.from ? routineIdx + ':' + exit.from : exit.from
          exit.next = exit.next ? rId + ':' + exit.next : exit.next
          updateModifiers(exit, rId)
        }
      }
      if (rSeq.next) {
        rSeq.next = rId + ':' + rSeq.next
      }
    }
    ++rId
  }
  story.sequences = allSequences.reduce((prev, curr) => [...prev, ...curr.sequences], [])
  story.firstSequence = '0:' + story.firstSequence

  // update events
  for (let evt of story.events) {
    if (evt.condition) {
      updateCondition(evt.condition, '0')
    }
    for (let action of (evt.actions || [])) {
      if (action.kind === 'redirect' && action.params.target) {
        action.params.target = '0:' + action.params.target
      }
    }
  }

  // update counters gauges conditions
  for (let counter of story.counters) {
    if (counter.gauge && counter.gauge.condition) {
      updateCondition(counter.gauge.condition, '0')
    }
  }
}