import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Fragment } from 'react'
import { Icon } from 'semantic-ui-react'
import Div100vh from 'react-div-100vh'
import SequenceRenderer from './sequence-renderer'
import Jukebox from './jukebox'
import ObjectModal from './object-modal'
import StoryStartScreen from './story-start-screen'
import StoryPlayerOptions from './story-player-options'
import StoryCredits from './story-credits'
import StoryAboutScreen from './story-about-screen'
import ConfirmAction from './confirm-action'
import WinObjectsPanel from './win-objects-panel'
import { executeConditions, respectCondition } from './modifiers/condition-helpers'
import { executeAction } from './modifiers/action-helpers'
import { CounterGauge } from './counter-gauge'
import SvgIcon from './svg-icon'
import { PlayerLocalesContext, locales } from './story-player-locales'
import humanizeDuration from 'humanize-duration'
import { moikiThemes, moikiDefaultTheme, getCombinedStyles } from './story-theme-utils'
import StoryBackground from './background/story-background'
import RandomModal from './random-modal/random-modal'
import './story-player.css'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const generateRandomSeeds = () => [0,1,2,3,4,5,6,7,8,9].map(x => Math.random())

let isLoadingSavedGame = false

const StoryPlayer = forwardRef((props, playerRef) => {
  const {
    reducedViewport=false,
    bgStylesFix={},
    onReady,
    onStart,
    onSequenceChanged,
    onSavePoint,
    onSaveReset,
    onHappyEnd,
    onTragicEnd,
    soundsEnabled=true,
    displayExitButton=false,
    onExit,
    showPlatformInfo=true,
    canShowStats=true
  } = props

  const [story, setStory] = useState(null)
  const [numSequencesTotal, setNumSequencesTotal] = useState(0)
  const [sequencesShown, setSequencesShown] = useState([])
  const [numHeroesTotal, setNumHeroesTotal] = useState(0)
  const [heroesShown, setHeroesShown] = useState([])
  const [numObjectsTotal, setNumObjectsTotal] = useState(0)
  const [objectsShown, setObjectsShown] = useState([])
  const [sequenceId, setSequenceId] = useState(null)
  const [gameData, setGameData] = useState(null)
  const [modalObjData, setModalObjData] = useState(null)
  const [modalRandomData, setModalRandomData] = useState(null)
  const [storyActions, setStoryActions] = useState(null)
  const [storyActionIndex, setStoryActionIndex] = useState(null)
  const [storyEvents, setStoryEvents] = useState(null)
  const [storyEventIndex, setStoryEventIndex] = useState(null)
  const [showStartScreen, setShowStartScreen] = useState(!props.isPreview)
  const [gameOptions, setGameOptions] = useState({})
  const [soundsLoaded, setSoundsLoaded] = useState(false)
  const [soundsProgress, setSoundsProgress] = useState(0)
  const [startLoadingTime, setStartLoadingTime] = useState(0)
  const [loadingTime, setLoadingTime] = useState(null)
  const [path, setPath] = useState([])
  const [routinesExit, setRoutinesExit] = useState([])
  const [eventsDone, setEventsDone] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [numReplays, setNumReplays] = useState(0)
  const [numVictories, setNumVictories] = useState(0)
  const [numDefeats, setNumDefeats] = useState(0)
  const [numUndo, setNumUndo] = useState(0)
  const [savePoints, setSavePoints] = useState([])
  const [showCredits, setShowCredits] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showObjects, setShowObjects] = useState(false)
  const [gauges, setGauges] = useState(null)
  const [defaultTheme, setDefaultTheme] = useState(null)
  const [currentTheme, setCurrentTheme] = useState(null)
  const [styles, setStyles] = useState({})
  const [containerStyles, setContainerStyles] = useState({})
  const [computedStyles, setComputedStyles] = useState({})
  const [textStyle, setTextStyle] = useState({})
  const [showConfirmLoadGame, setShowConfirmLoadGame] = useState(null)
  const [showConfirmRestartGame, setShowConfirmRestartGame] = useState(null)

  // used by mobile app : allow to reload when app resumed from background (should fix issue with sounds...)
  const [lastTimeOnBackground, setLastTimeOnBackground] = useState(0)

  useEffect(() => {
    if (!soundsEnabled && !showConfirmLoadGame) {
      setLastTimeOnBackground(Date.now())
    } else if (soundsEnabled && !showConfirmLoadGame && lastTimeOnBackground > 0) {
      const backgroundDuration = Date.now() - lastTimeOnBackground
      setLastTimeOnBackground(0)
      if (backgroundDuration > 12000) {
        //jukeboxRef.current ? jukeboxRef.current.getCurrentLoop() : null
        props.onExit(true)
      }
    }
  }, [soundsEnabled])

  const jukeboxRef = useRef(null)

  useImperativeHandle(playerRef, () => ({
    updateSequence,
    loadGame
  }))

  const getInitialGameData = () => {
    let counters = []
    if (props.story && props.story.counters) {
      counters = Object.entries(props.story.counters).map(([_, x]) => ({id: x.id, value: x.defaultValue || 0}))
    }
    let textvars = []
    if (props.story && props.story.textvars) {
      textvars = Object.entries(props.story.textvars).map(([_, x]) => ({id: x.id, value: (x.values && x.values.length > 0) ? x.values[0].id : x.name}))
    }
    return props.defaultGameData ? {counters, textvars, ...props.defaultGameData} : {counters, textvars}
  }

  useEffect(() => {
    if (!props.story) {
      throw new Error('No story was given!')
    }
    let startupTheme
    for (let th of props.story.themes) {
      if (th.identifier === (props.story.sequences[props.story.firstSequence].themeId || 'default')) {
        startupTheme = th
      }
      if (th.identifier === 'default') {
        const baseTheme = moikiThemes.find(moikiBaseTheme => moikiBaseTheme.name === th.name) || moikiDefaultTheme
        setDefaultTheme({...baseTheme, ...th})
      }
    }
    setCurrentTheme(startupTheme)

    setHeroesShown([])
    setObjectsShown([])
    if (props.story.counters) {
      const counters = Object.entries(props.story.counters).map(([_, x]) => x)
      setGauges(counters.filter(c => !!c.gauge))
    }
    setSequencesShown([props.story.firstSequence])
    setSequenceId(props.story.firstSequence)
    setRoutinesExit([])
    setEventsDone([])

    setNumSequencesTotal(Object.entries(props.story.sequences).filter(([_, x]) => !x.routineId).filter(([_, x]) => !(x.final && x.hasOwnProperty('routineExitLabel'))).length)
    const allAssets = Object.entries(props.story.assets).map(([_, s]) => s)
    setNumHeroesTotal(allAssets.filter(({kind}) => kind === 'hero').map(({id}) => id).length)
    setNumObjectsTotal(allAssets.filter(({kind}) => kind === 'object').map(({id}) => id).length)
    const initialGameData = getInitialGameData()
    const firstSeq = props.story.sequences ? props.story.sequences[props.story.firstSequence] : null
    if (firstSeq && firstSeq.choices && firstSeq.choices.length > 0) {
      setSavePoints([{
        id: props.story.firstSequence,
        path: [props.story.firstSequence],
        routinesExit: [],
        eventsDone: [],
        currentLoop: firstSeq.soundLoop && firstSeq.soundLoop.sound ? firstSeq.soundLoop : null,
        currentTheme: startupTheme || 'default',
        gameData: initialGameData,
        randomSeeds: generateRandomSeeds()
      }])
    } else {
      setSavePoints([])
    }
    setStory(props.story)
    setGameData(initialGameData)
  }, [props.story])

  useEffect(() => {
    if (story && numReplays === 0) {
      updateSequence()
    }
  }, [story, numReplays])

  const getStats = () => {
    return {
      numReplays,
      numVictories,
      numDefeats,
      numUndo,
      numSequencesShown: sequencesShown.length,
      percentViewedSequences: (sequencesShown.length / numSequencesTotal).toFixed(2)
    }
  }

  useEffect(() => {
    if (onSequenceChanged) {
      onSequenceChanged(sequenceId)
    }
    if (props.story.sequences && sequenceId) {
      const seq = props.story.sequences[sequenceId]
      if (seq.final && !seq.hasOwnProperty('routineExitLabel')) {
        const data = getStats()
        if (seq.isHappyEnd && onHappyEnd) {
          onHappyEnd(data)
        } else if (!seq.isHappyEnd && onTragicEnd) {
          onTragicEnd(data)
        }
      }
      if (!isLoadingSavedGame && onSavePoint && path && path.length > 1) {
        onSavePoint({
          data: [...savePoints, {
            id: sequenceId,
            path: path,
            currentLoop: jukeboxRef.current ? jukeboxRef.current.getCurrentLoop() : null,
            currentTheme: currentTheme ? currentTheme.identifier : null,
            gameData,
            routinesExit: routinesExit,
            eventsDone: eventsDone,
            randomSeeds: generateRandomSeeds()
          }],
          stats: {
            sequencesShown,
            heroesShown,
            objectsShown,
            numReplays,
            numVictories,
            numDefeats,
            numUndo
          }
        })
      }
    }
    if (isLoadingSavedGame) {
      isLoadingSavedGame = false
    }
  }, [sequenceId, path])

  useEffect(() => {
    isLoadingSavedGame = false
    setStartLoadingTime(new Date().getTime())
  }, [])

  useEffect(() => {
    const now = new Date().getTime()
    if (story) {
      const numSounds = Object.keys(story.sounds || {}).length
      if (soundsLoaded || numSounds === 0) {
        const loadTime = now - startLoadingTime
        setLoadingTime(loadTime)
        if (onReady) {
          onReady()
        }
      }
    }
  }, [soundsLoaded, story])

  const loadGame = (game) => {
    setShowConfirmLoadGame(game)
  }

  const handleCancelLoadGame = () => {
    setShowConfirmLoadGame(null)
    startStory({sounds: true})
  }

  const handleConfirmLoadGame = () => {
    isLoadingSavedGame = true
    const { data, stats } = showConfirmLoadGame
    setShowConfirmLoadGame(null)
    if (onStart) {
      onStart(loadingTime)
    }
    setShowStartScreen(false)
    setGameOptions({sounds: true})
    if (data && data.length > 0) {
      let dataPoint = data.pop()
      setSavePoints(data)
      const {id, path: undoPath, gameData: undoGameData, currentLoop, currentTheme: undoTheme, routinesExit: undoExit, eventsDone: undoEvents} = dataPoint
      const seqTheme = props.story.themes.find(th => th.identifier === (undoTheme || 'default'))
      setCurrentTheme(seqTheme)
      setGameData(undoGameData)
      setPath(undoPath)
      setSequenceId(id)
      setRoutinesExit(undoExit)
      setEventsDone(undoEvents)
      // update stats...
      setSequencesShown(stats.sequencesShown)
      setHeroesShown(stats.heroesShown)
      setObjectsShown(stats.objectsShown)
      setNumReplays(stats.numReplays)
      setNumVictories(stats.numVictories)
      setNumDefeats(stats.numDefeats)
      setNumUndo(stats.numUndo)
      if (currentLoop) {
        playSound(currentLoop, true)
      }
    }
  }

  const getSequence = (id) => {
    return story && story.sequences ? story.sequences[id] : null
  }

  const previous = usePrevious({ sequenceId })
  useEffect(() => {
    const seq = getSequence(sequenceId)
    if (previous && previous.sequenceId !== sequenceId) {
      if (seq.final && !seq.hasOwnProperty('routineExitLabel')) {
        fadeLoop()
        if (seq.isHappyEnd && styles.sfx && styles.sfx.gameWin) {
          playSound(styles.sfx.gameWin)
        } else if (!seq.isHappyEnd && styles.sfx && styles.sfx.gameLose) {
          playSound(styles.sfx.gameLose)
        }
      }
      if (seq && seq.soundSfx) {
        playSound(seq.soundSfx)
      }
      if (seq && seq.soundLoop && (!seq.final || seq.hasOwnProperty('routineExitLabel'))) {
        playSound(seq.soundLoop, true)
      }
    }

    // update sequence theme
    if (seq) {
      const seqTheme = props.story.themes.find(th => th.identifier === seq.themeId)
      if (seqTheme && seqTheme.identifier !== (currentTheme || {}).identifier) {
        setCurrentTheme(seqTheme)
      }
    }
  }, [sequenceId, getSequence])

  const getAssetById = (id) => {
    return story.assets[id] || {}
  }

  const getIconById = (id) => {
    return getAssetById(id).icon
  }

  const nextEvent = () => {
    setStoryEventIndex(storyEventIndex + 1)
  }

  const executeNextEvent = (event) => {
    if (event.condition && respectCondition(story, gameData, path, event.condition)) {
      if (event.kind === 'once') {
        setEventsDone([...eventsDone, event.id])
      }
      if (event.actions && event.actions.length > 0) {
        const actionResult = performAction(event.actions[0])
        if (!actionResult) {
          return
        }
        if (typeof actionResult === 'string' && story.sequences[actionResult]) {
          setRoutinesExit([])
          setStoryEvents({id: actionResult, conditions: [], events: storyEvents.events})
        }
      }
    }
    nextEvent()
  }

  useEffect(() => {
    const { events, id, conditions } = (storyEvents || {})
    if (events && (!!storyEventIndex || storyEventIndex === 0)) {
      if (events.length > storyEventIndex) {
        executeNextEvent(events[storyEventIndex])
      } else {
        setStoryEvents(null)
        setStoryEventIndex(null)
        updateSequence(id, conditions)
      }
    }
  }, [storyEvents, storyEventIndex])

  const executeEvents = (id, conditions) => {
    const events = story.events.filter(e => !eventsDone.includes(e.id))
    setStoryEvents({id, conditions, events})
    setStoryEventIndex(0)
  }

  const nextAction = () => {
    setStoryActionIndex(storyActionIndex + 1)
  }

  const performAction = (action) => {
    const actionResult = executeAction(story, gameData, action)
    if (actionResult) {
      if (actionResult.heroShown && !heroesShown.includes(actionResult.heroShown)) {
        setHeroesShown([...heroesShown, actionResult.heroShown])
      }
      if (actionResult.objectShown && !objectsShown.includes(actionResult.objectShown)) {
        setObjectsShown([...objectsShown, actionResult.objectShown])
      }
      if (actionResult.sound && styles.sfx && styles.sfx[actionResult.sound]) {
        playSound(styles.sfx[actionResult.sound])
      }
      if (actionResult.gameData) {
        setGameData(actionResult.gameData)
      }
      if (actionResult.modal) {
        if (actionResult.modal.asset) {
          setModalObjData(actionResult.modal)
        } else if (actionResult.modal.randomValue) {
          setModalRandomData(actionResult.modal)
        }
        return false
      }
      if (actionResult.redirectTo) {
        return actionResult.redirectTo
      }
    }
    return true
  }

  const executeNextAction = (action) => {
    if (performAction(action)) {
      nextAction()
    }
  }

  useEffect(() => {
    const { actions, id, conditions } = (storyActions || {})
    if (actions && (!!storyActionIndex || storyActionIndex === 0)) {
      if (actions.length > storyActionIndex) {
        executeNextAction(actions[storyActionIndex])
      } else {
        setStoryActions(null)
        setStoryActionIndex(null)
        if (story.events && story.events.length > 0) {
          executeEvents(id, conditions)
        } else {
          updateSequence(id, conditions)
        }
      }
    }
  }, [storyActions, storyActionIndex])

  const executeActions = (id, actions, conditions) => {
    setStoryActions({id, actions, conditions})
    setStoryActionIndex(0)
  }

  const gotoSequence = (id, actions, conditions, exits=null) => {
    if (actions && actions.length > 0) {
      executeActions(id, actions, conditions)
    } else if (story.events && story.events.length > 0) {
      executeEvents(id, conditions)
    } else {
      updateSequence(id, conditions, exits)
    }
  }

  const updateSequence = (id, conditions, exits=null) => {
    if (!id) {
      setNumReplays(numReplays + 1)
      if (sequencesShown.length === 0) {
        setSequencesShown([story.firstSequence])
      }
      if (story.sequences[story.firstSequence] && story.sequences[story.firstSequence].choices && story.sequences[story.firstSequence].choices.length > 0) {
        setSavePoints([{
          id: story.firstSequence,
          path: [story.firstSequence],
          routinesExit: [],
          eventsDone: [],
          currentLoop: story.sequences[story.firstSequence].soundLoop && story.sequences[story.firstSequence].soundLoop.sound ? story.sequences[story.firstSequence].soundLoop : null,
          currentTheme: story.sequences[story.firstSequence].themeId || 'default',
          gameData,
          randomSeeds: generateRandomSeeds()
        }])
      } else {
        setSavePoints([])
      }
      setRoutinesExit([])
      setEventsDone([])
      setPath([story.firstSequence])
      setGameData(getInitialGameData())
      setSequenceId(story.firstSequence)
      stopAllSounds()
      setupFirstSequence(gameOptions)
      return
    }
    let next = id
    if (conditions && conditions.length > 0) {
      const conditionNext = executeConditions(story, gameData, path, conditions)
      if (conditionNext) {
        next = conditionNext
      }
    }
    const updatedExits = exits || routinesExit
    
    // manage redirect for routines (enter in routine)
    let realId = story.sequences[next] && story.sequences[next].redirectTo ? story.sequences[next].redirectTo : next
    let newPath = [...path, next]
    let newRoutinesExit = updatedExits
    if (realId !== next) {
      newPath = [...path, next, realId]
      newRoutinesExit = [...updatedExits, story.sequences[next].routineExit] 
      setRoutinesExit(newRoutinesExit)
    }
    // manage redirect for routines (exit from routine)
    if (story.sequences[realId] && story.sequences[realId].final && updatedExits && updatedExits.length > 0) {
      const rExit = updatedExits[updatedExits.length-1].find(x => x.from === realId)
      if (rExit) {
        newRoutinesExit = updatedExits.slice(0,-1)
        setRoutinesExit(newRoutinesExit)
        gotoSequence(rExit.next, rExit.actions, rExit.conditions, newRoutinesExit)
        return
      }
    }
    setPath(newPath)
    if (!sequencesShown.includes(realId)) {
      setSequencesShown([...sequencesShown, realId])
    }
    if (story.sequences[realId] && story.sequences[realId].final && !story.sequences[realId].hasOwnProperty('routineExitLabel')) {
      if (story.sequences[realId].isHappyEnd) {
        setNumVictories(numVictories + 1)
      } else {
        setNumDefeats(numDefeats + 1)
      }
    }

    // peut etre une betise de changer le theme ici...
    // + si ca ne marche pas -> virer la possibilité de changer le theme sur les sequence d'usage des routines...
    // + si ca marche -> voir ce qu'il est possible de faire sur les sons...
    let thId = story.sequences[realId] && story.sequences[realId].themeId
    if (thId) {
      const seqTheme = props.story.themes.find(th => th.identifier === thId)
      if (seqTheme && seqTheme.identifier !== (currentTheme || {}).identifier) {
        setCurrentTheme(seqTheme)
      }
    }
    setSequenceId(realId)
    if (story.sequences[realId] && story.sequences[realId].choices && story.sequences[realId].choices.length > 0) {
      const newSavePoints = [...savePoints, {
        id: realId,
        path: newPath,
        routinesExit: newRoutinesExit,
        eventsDone: eventsDone,
        currentLoop: jukeboxRef.current ? jukeboxRef.current.getCurrentLoop() : null,
        currentTheme: thId || (currentTheme ? currentTheme.identifier : null),
        gameData,
        randomSeeds: generateRandomSeeds()
      }]
      setSavePoints(newSavePoints)
    }
  }

  const areArrayEquals = (a1, a2) => a1.length === a2.length && a1.every((v, i) => v === a2[i])

  const undo = () => {
    let undoPoint = savePoints[savePoints.length - 1]
    if (areArrayEquals(path, undoPoint.path)) {
      const newSavePoint = savePoints.slice(0, -1)
      if (newSavePoint.length === 0) {
        return
      }
      setSavePoints(newSavePoint)  
      undoPoint = savePoints[savePoints.length - 2]
    }
    stopAllSounds()
    const {id, path: undoPath, gameData: undoGameData, currentLoop, currentTheme: undoTheme, routinesExit: undoExit, eventsDone: undoEvents} = undoPoint
    const seqTheme = props.story.themes.find(th => th.identifier === (undoTheme || 'default'))
    setCurrentTheme(seqTheme)
    setGameData(undoGameData)
    setPath(undoPath)
    setRoutinesExit(undoExit)
    setEventsDone(undoEvents)
    setNumUndo(numUndo + 1)
    if (currentLoop) {
      playSound(currentLoop, true)
    }
    setSequenceId(id)
  }

  const modalClosed = () => {
    setModalObjData(null)
    setModalRandomData(null)
    if (storyEventIndex !== null) {
      nextEvent()
    } else {
      nextAction()
    }
  }

  useEffect(() => {
    if (currentTheme) {
      const combinedStyles = getCombinedStyles(story, currentTheme.identifier)
      const {
        sequenceStyles,
        textStyle: sequenceTextStyle
      } = combinedStyles

      setStyles(sequenceStyles)
      setContainerStyles({
        background: sequenceStyles.background,
        fontFamily : sequenceStyles.fontFamily,
        ...(props.styles || {})
      })
      
      setTextStyle(sequenceTextStyle)
      setComputedStyles(combinedStyles)
    }
  }, [currentTheme])

  const playSound = (soundRef, isLoop=false) => {
    if (jukeboxRef.current) {//} && gameOptions.sounds) { --> this option is useless and creates bugs when loading a saved game...
      jukeboxRef.current.playSound(soundRef, isLoop)
    }
  }

  const stopAllSounds = () => {
    if (jukeboxRef.current && gameOptions.sounds) {
      jukeboxRef.current.stopAllSounds()
    }
  }

  const fadeLoop = () => {
    if (jukeboxRef.current && gameOptions.sounds) {
      jukeboxRef.current.fadeLoop()
    }
  }

  const onPlayClickSound = () => {
    if (styles.sfx && styles.sfx.button) {
      playSound(styles.sfx.button)
    }
  }

  const setupFirstSequence = (options) => {
    stopAllSounds()
    const firstSeq = props.story.sequences ? props.story.sequences[props.story.firstSequence] : null
    if (options.sounds && !props.isPreview && jukeboxRef.current) {
      if (firstSeq && firstSeq.soundLoop && firstSeq.soundLoop.sound) {
        jukeboxRef.current.playSound(firstSeq.soundLoop, true)
      }
    }
    let themeId = 'default'
    if (firstSeq && firstSeq.themeId) {
      themeId = firstSeq.themeId
    }
    const seqTheme = props.story.themes.find(th => th.identifier === themeId)
    if (seqTheme && seqTheme.identifier !== (currentTheme || {}).identifier) {
      setCurrentTheme(seqTheme)
    }
  }

  const startStory = (options) => {
    if (onStart) {
      onStart(loadingTime)
    }
    setShowStartScreen(false)
    setGameOptions(options)
    setNumReplays(1)
    setupFirstSequence(options)
  }

  const reset = () => {
    if (onSaveReset) {
      onSaveReset()
    }
    hideOptionsPanel(true)
    setNumUndo(0)
    setNumVictories(0)
    setNumDefeats(0)
    setHeroesShown([])
    setObjectsShown([])
    setSequencesShown([story.firstSequence])
    setNumReplays(0)
    setRoutinesExit([])
    setEventsDone([])
  }

  const restart = () => {
    hideOptionsPanel(true)
    updateSequence()
  }

  const resetFromOptions = () => {
    onReplayHandler(true)
  }
  
  const restartFromOptions = () => {
    onReplayHandler()
  }

  const handleCancelRestartGame = () => {
    setShowConfirmRestartGame(false)
  }

  const handleConfirmRestartGame = () => {
    const isReset = showConfirmRestartGame === 'reset'
    setShowConfirmRestartGame(false)
    if (isReset) {
      reset()
    } else {
      restart()
    }
  }

  const showAboutPanel = () => {
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause()
    }
    setShowAbout(true)
  }

  const hideAboutPanel = () => {
    setShowAbout(false)
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause()
    }
  }

  const showOptionsPanel = () => {
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause()
    }
    setShowOptions(true)
  }

  const hideOptionsPanel = (resetAllSounds=false) => {
    setShowOptions(false)
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause(resetAllSounds)
    }
  }

  const showObjectsPanel = () => {
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause()
    }
    setShowObjects(true)
  }

  const hideObjectsPanel = () => {
    setShowObjects(false)
    if (jukeboxRef.current) {
      jukeboxRef.current.togglePause()
    }
  }

  const onReplayHandler = (isReset=false) => {
    if (onSavePoint) {
      // since we have saved game in localStorage, we alert user before proceed
      setShowConfirmRestartGame(isReset ? 'reset' : true)
    } else if (isReset) {
      reset()
    } else {
      restart()
    }    
  }

  const displayedGauges = story && gauges && gauges.length > 0 ?
    gauges.filter(({gauge}) => (!gauge.condition || respectCondition(story, gameData, path, gauge.condition))) :
    null

  const strings = locales[props.lang || 'fr']
  const showTitleBar = story && story.meta && story.meta.options.displayTitleBar
  return story ? (
    <PlayerLocalesContext.Provider value={strings}>
      <Fragment>
        <div className="story-player-container" style={containerStyles}>
          <Div100vh className={ showStartScreen || showOptions || showAbout || showObjects ? 'blurred' : '' }>
            { showTitleBar && (
              <div className="topbar" style={{background: styles.topbarBackground, color: styles.topbarText, fontFamily: defaultTheme.styles.fontFamily}}>
                MOIKI { story.meta ? '- ' + story.meta.name : '' }
              </div>
            )}
            <div style={{ display: 'flex', width: '100%', height: showTitleBar ? 'calc(100% - 19px)' : '100%', position: 'relative' }}>
              <StoryBackground theme={currentTheme} images={story.images} bgStylesFix={bgStylesFix} />
              <div className="main-content">
                { (gameData.hero || (gameData.objects && gameData.objects.length > 0) || (displayedGauges && displayedGauges.length > 0)) && (
                  <div className="game-bar" style={{ background: styles.assetsBackground }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      { gameData.hero ? (
                        <SvgIcon
                          icon={getIconById(gameData.hero)}
                          className="hero"
                          style={{ fill: styles.assetsFill, stroke: styles.assetsFill }}
                        />
                      ) : (<div />)}
                      <div className="gauges-container">
                        <div className="gauges-container-wrapper" style={{ color: styles.assetsFill }}>
                          { displayedGauges && displayedGauges.sort((a,b) => a.gauge.order - b.gauge.order).map((item, idx) => {
                            const { gauge, ...counter } = props.story.counters[item.id] || {}
                            const { value } = gameData.counters.find(x => x.id === item.id)
                            return (
                              <CounterGauge
                                key={'gauge-' + idx}
                                value={(isNaN(value) || value === null) ? counter.defaultValue : value}
                                gauge={gauge}
                                counter={counter}
                                styles={styles}
                                computedStyles={computedStyles}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="game-objects">
                      {gameData.objects && gameData.objects.length > 0 && (
                        <button
                          style={ computedStyles.button }
                          onClick={showObjectsPanel}
                        ><Icon name='star' style={{ margin: 0 }} /> ({gameData.objects.length})</button>
                      )}
                    </div>
                  </div>
                )}
                { (!modalRandomData && !modalObjData && !storyActionIndex && !storyEventIndex) && (
                  <SequenceRenderer
                    story={story}
                    gameData={gameData}
                    path={path}
                    routineExit={routinesExit && routinesExit.length > 0 ? routinesExit[routinesExit.length-1] : []}
                    styles={computedStyles}
                    playClickSound={onPlayClickSound}
                    sequence={ getSequence(sequenceId) }
                    gotoSequence={gotoSequence}
                    theme={{styles}}
                    showStats={showOptionsPanel}
                    reducedViewport={reducedViewport}
                    canUndo={story.meta && story.meta.options && story.meta.options.allowBack && path.length > 1 && ((savePoints.length === 1 && !areArrayEquals(path, savePoints[0].path)) || savePoints.length > 1)}
                    undo={undo}
                    canAddReview={false}
                    canShowStats={canShowStats}
                    onReplay={onSavePoint ? onReplayHandler : null}
                    randomSeeds={savePoints.length > 0 ? savePoints[savePoints.length - 1].randomSeeds : [0, 0.3, 0.5, 0.6]}
                  />
                )}
              </div>
            </div>
            { props.showMenu && (
              <Fragment>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    zIndex: 4
                  }}
                  className="player-menu"
                >
                  { (story.meta && story.meta.options.allowBack && path.length > 1 && savePoints.length > 0) && (
                    <button
                      disabled={ savePoints.length === 1 && areArrayEquals(path, savePoints[0].path) }
                      style={ computedStyles.button }
                      onClick={undo}
                    ><Icon name='undo' style={{ margin: 0 }} /></button>
                  )}
                  <button
                    style={ computedStyles.button }
                    onClick={showOptionsPanel}
                  ><Icon name='setting' style={{ margin: 0 }} /></button>
                  <button
                    style={ computedStyles.button }
                    onClick={showAboutPanel}
                  ><Icon name='info' style={{ margin: 0 }} /></button>
                  { displayExitButton && (
                    <button
                      style={ computedStyles.button }
                      onClick={() => onExit()}
                    ><Icon name='power off' style={{ margin: 0 }} /></button>
                  )}
                </div>
                { (story.meta && story.meta.options.showStats) && (
                  <div style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    padding: '0.2em 0.5em',
                    borderRadius: '1em',
                    opacity: .7,
                    ...computedStyles.sequence
                  }}>{sequencesShown.length} / {numSequencesTotal}</div>
                )}
              </Fragment>
            )}
          </Div100vh>
        </div>
        { modalObjData && (
          <ObjectModal
            textStyle={textStyle}
            params={modalObjData}
            theme={{styles}}
            onClosed={modalClosed}
          />
        )}
        { modalRandomData && (
          <RandomModal
            textStyle={textStyle}
            params={modalRandomData}
            theme={{styles}}
            onClosed={modalClosed}
          />
        )}
        { story.sounds && (
          <Jukebox
            ref={jukeboxRef}
            sounds={story.sounds}
            onReady={() => setSoundsLoaded(true)}
            onProgress={(percent) => setSoundsProgress(percent)}
          />
        )}
        { showConfirmLoadGame && (
          <ConfirmAction
            alternate={true}
            size='mini'
            title={strings.RESUME}
            content={
              <div style={{ padding: '1em' }}>
                <p>
                  {strings.RESUME_MSG}
                </p>
                <p>
                  <b>{(strings.RESUME_DATE || '').split('%s').join(humanizeDuration(showConfirmLoadGame.delay, { language: props.lang || 'fr', round: true, largest: 2 }))}</b>
                </p>
                <p style={{ color: '#7a7a7a' }}><em>{strings.RESUME_ALERT}</em></p>
              </div>
            }
            cancelLabel={strings.DO_NOT_RESUME}
            cancelIcon={<Icon name='redo' />}
            confirmLabel={strings.LOAD}
            open={showConfirmLoadGame ? true : false}
            onCancel={handleCancelLoadGame}
            onConfirm={handleConfirmLoadGame}
          />
        )}
        { showConfirmRestartGame && (
          <ConfirmAction
            size='mini'
            title={showConfirmRestartGame === 'reset' ? strings.RESET : strings.RESTART}
            content={
              <div style={{ padding: '1em' }}>
                <p>
                  {showConfirmRestartGame === 'reset' ? strings.RESET_MSG : strings.RESTART_MSG}
                  <br/><br/>
                  <b>{strings.WILL_LOSE_BACKUP}</b>
                </p>
              </div>
            }
            cancelLabel={strings.CANCEL}
            confirmLabel={showConfirmRestartGame === 'reset' ? strings.RESET_GAME : strings.RESTART}
            open={showConfirmRestartGame ? true : false}
            onCancel={handleCancelRestartGame}
            onConfirm={handleConfirmRestartGame}
          />
        )}
        { showStartScreen && !showCredits && (
          <div className='story-overlay'>
            <StoryStartScreen
              story={story}
              soundsLoaded={soundsLoaded}
              soundsProgress={soundsProgress}
              onStart={startStory}
              onShowCredits={() => setShowCredits(true)}
            />
          </div>
        )}
        { showOptions && !showCredits && (
          <div className='story-overlay'>
            <StoryPlayerOptions
              story={story}
              onClose={() => hideOptionsPanel()}
              onShowCredits={() => setShowCredits(true)}
              onReset={resetFromOptions}
              onRestart={restartFromOptions}
              gameOptions={gameOptions}
              stats={{
                numSequencesShown: sequencesShown.length,
                numSequencesTotal,
                numHeroesShown: heroesShown.length,
                numHeroesTotal,
                numObjectsShown: objectsShown.length,
                numObjectsTotal,
                numUndo,
                numReplays,
                numVictories,
                numDefeats
              }}
            />
          </div>
        )}
        { showAbout && !showCredits && (
          <div className='story-overlay'>
            <StoryAboutScreen
              story={story}
              onClose={hideAboutPanel}
              showPlatformInfo={showPlatformInfo}
            />
          </div>
        )}
        { showCredits && (
          <div className='story-overlay'>
            <StoryCredits
              story={story}
              onClose={() => setShowCredits(false)}
            />
          </div>
        )}
        { showObjects && (
          <div className='story-overlay'>
            <WinObjectsPanel
              getAssetById={getAssetById}
              objects={gameData.objects}
              onClose={hideObjectsPanel}
              styles={styles}
            />
          </div>
        )}
      </Fragment>
    </PlayerLocalesContext.Provider>
  ) : null
})

export default StoryPlayer
