import React from 'react'

const NumericGauge = ({counter, value, styles}) => (
  <div style={styles}>
    <div>{counter.name}</div>
    <div>{(isNaN(value) || value === null) ? counter.defaultValue : value}</div>
  </div>
)

const TotalGauge = ({counter, value, styles}) => (
  <div style={styles}>
    <div>{counter.name}</div>
    <div>{(isNaN(value) || value === null) ? counter.defaultValue : value} / {counter.max}</div>
  </div>
)

const PercentGauge = ({counter, value, styles}) => (
  <div style={styles}>
    <div>{counter.name}</div>
    <div>{`${(((value - counter.min) / (counter.max - counter.min)) * 100)}%`}</div>
  </div>
)
  
const BarGauge = ({counter, value, styles}) => (
  <div style={styles}>
    <div>{counter.name}</div>
    <div style={{ margin: '.2em 0', width: 100, display: 'block', borderRadius: '.5em', height: '1em', border: `2px ${styles.color} solid`, overflow: 'hidden', boxShadow: styles.textShadow }}>
      <div style={{ width: `${(((value -counter.min) / (counter.max - counter.min)) * 100)}%`, display: 'block', height: '1em', background: styles.color }}></div>
    </div>
  </div>
)


export const CounterGauge = ({styles, computedStyles,...props}) => {
  const { gauge, ...counterProps } = props
  if (!computedStyles.sequence) {
    return null
  }
  const gaugeStyles = {...computedStyles.sequence, ...computedStyles.textStyle, color: styles.assetsFill, background: 'none'}
  switch (gauge.kind) {
    case 'bar': return <BarGauge {...counterProps} styles={gaugeStyles} />
    case 'percent': return <PercentGauge {...counterProps} styles={gaugeStyles} />
    case 'total': return <TotalGauge {...counterProps} styles={gaugeStyles} />
    default: return <NumericGauge {...counterProps} styles={gaugeStyles} />
  }
}