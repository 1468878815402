const open = window.XMLHttpRequest.prototype.open
const send = window.XMLHttpRequest.prototype.send

export const startSniff = (progressCallback) => {
  window.XMLHttpRequest.prototype.open = function () {
    const [method, url] = arguments
    this.method = method
    this.requestURL = url
    return open.apply(this, arguments)
  }

  window.XMLHttpRequest.prototype.send = function () {
    this.addEventListener('error', (evt) => {
      if (progressCallback) {
        progressCallback(evt.target.requestURL, 100)
      }
    })

    this.onprogress = (evt) => {
      if (progressCallback) {
        const percentLoaded = (evt.loaded / evt.total) * 100
        progressCallback(evt.target.requestURL, percentLoaded)
      }
    }

    return send.apply(this, arguments)
  }
}

export const stopSniff = () => {
  window.XMLHttpRequest.prototype.open = open
  window.XMLHttpRequest.prototype.send = send
}
