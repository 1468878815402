//import * as easings from 'd3-ease'

const animationKinds = {
  fade: {
    label: 'Fade',
    fn: () => {
      return ''
    }
  },
  zoom: {
    label: 'Zoom',
    fn: (t) => {
      const scaleValue = 1 - t / 100
      return `translateY(${t}px) scale(${scaleValue}, ${scaleValue})`
    }
  },
  unzoom: {
    label: 'Dezoom',
    fn: (t) => {
      const scaleValue = 1 + t / 100
      return `translateY(${t}px) scale(${scaleValue}, ${scaleValue})`
    }
  },
  pop: {
    label: 'Rotation',
    fn: (t) => {
      const alphaValue = 1 - Math.abs(t) / 100
      const rot = 90 - 90 * alphaValue
      return `translateY(${t}px) rotateY(-${rot}deg)`
    }
  }/*,
  another: {
    label: 'other',
    fn: (t) => {
      const alphaValue = 1 - Math.abs(t) / 100
      const scaleValue = 1 + t / 100
      const rot = 90 - 90 * scaleValue
      return `translateY(${t}px) rotateX(-${rot}deg)`
      //return `translateY(${t}px) scale(${scaleValue}, ${scaleValue})`
    }
  }*/
}

const getAnimationStyle = (id) => {
  const anim = animationKinds[id] || animationKinds.unzoom
  return anim.fn
}

export default {
  kinds: animationKinds,
  getAnimationStyle 
}