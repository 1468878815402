import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Fragment } from 'react'
import useSound from 'use-sound'
import { startSniff, stopSniff } from './xhr-sniffer'

const getSoundUrl = (sound) => {
  if (sound && sound.localFile) {
    return sound.localFile
  }
  if (sound && sound.previews) {
    return sound.previews['preview-lq-mp3']
  }
  return null
}

const JukeboxItem = forwardRef((props, ref) => {
  const { sound, isLoop, onLoad } = props
  const url = getSoundUrl(sound)
  const [, soundRef] = useSound(url)

  useImperativeHandle(ref, () => soundRef)

  useEffect(() => {
    if (soundRef.sound) {
      soundRef.sound.loop(isLoop)
      if (soundRef.sound.state() === 'loaded') {
        onLoad(url)
      }
      soundRef.sound.on('load', () => {
        onLoad(url)
      })
      soundRef.sound.on('fade', () => {
        if (soundRef.sound.volume() === 0) {
          soundRef.sound.stop()  
        }
      })
    }
    return () => {
      if (soundRef.sound) {
        soundRef.sound.off()
        soundRef.sound.stop()
      }
    }
  }, [soundRef.sound, url])

  return (
    <Fragment />
  )
})

let isJukeboxUnmounted = false

const Jukebox = forwardRef((props, ref) => {
  const { sounds, onReady, onProgress } = props
  const allSounds = Object.entries(sounds).map(([_, sound]) => sound) || []
  const [currentLoop, setCurrentLoop] = useState(null)
  const [currentLoopParams, setCurrentLoopParams] = useState(null)
  const [pausedSounds, setPausedSounds] = useState([])
  const [isPaused, setIsPaused] = useState(false)

  const soundsRefs = useRef({})
  allSounds.forEach(({id}) => {
    soundsRefs.current[id] = null
  })

  let loaded = 0

  const onSoundLoaded = (url) => {
    const progressDataRef = progressData.find(x => x.url === url)
    if (progressDataRef) {
      progressDataRef.loaded = 100
    }
    if (++loaded === allSounds.length) {
      onReady()
      stopSniff()
    }
  }

  const progressData = allSounds.map(({sound, id}) => {
    return { url: getSoundUrl(sound), loaded: 0, id }
  })

  useEffect(() => {
    isJukeboxUnmounted = false
    startSniff((target, percent) => {
      const progressDataRef = progressData.find(x => x.url === target)
      if (progressDataRef) {
        progressDataRef.loaded = percent
        let totalLoaded = 0
        progressData.forEach(x => totalLoaded += x.loaded)
        const totalProgress = totalLoaded / progressData.length
        onProgress(totalProgress)
        if (totalProgress === 100) {
          stopSniff()
        }
      }
    })
    return () => {
      stopSniff()
      isJukeboxUnmounted = true
    }
  }, [])

  useImperativeHandle(ref, () => ({
    getIsPaused: () => isPaused,
    addPausedSound: (snd) => {
      setPausedSounds([
        ...pausedSounds,
        snd
      ])
    },
    getCurrentLoop: () => {
      return {
        sound: currentLoop,
        params: currentLoopParams
      }
    },
    playSound: (soundRef, isLoop=false) => {
      if (soundRef) {
        const {sound, params} = soundRef
        if (sound === 'stop') {
          if (currentLoop && soundsRefs.current[currentLoop]) {
            const loop = soundsRefs.current[currentLoop].sound
            loop.fade(1, 0, 3)
            setCurrentLoop(null)
            setCurrentLoopParams(null)
          }
        } else if (soundsRefs.current[sound] && soundsRefs.current[sound].sound) {
          const howl = soundsRefs.current[sound].sound
          if (isLoop) {
            if (currentLoop && currentLoop !== sound) {
              const previousLoop = soundsRefs.current[currentLoop].sound
              previousLoop.fade(1, 0, 6)
              howl.fade(0, 1, 6)
            } else if (currentLoop) {
              howl.stop()
              howl.volume(1)
            } else {
              howl.volume(1)
            }
            setCurrentLoop(sound)
            setCurrentLoopParams(params)
          }
          if (soundRef.params && soundRef.params.volume) {
            howl.volume(soundRef.params.volume)
          }
          if (soundRef.params && soundRef.params.delay) {
            setTimeout(() => {
              if (!isJukeboxUnmounted && ref.current) {
                if (ref.current.getIsPaused()) {
                  ref.current.addPausedSound({id: soundRef.sound, time: 0})
                } else {
                  howl.play()
                }
              }
            }, soundRef.params.delay * 1000)
          } else {
            howl.play()
          }
        }
      }
    },
    fadeLoop: () => {
      if (currentLoop) {
        const loop = soundsRefs.current[currentLoop].sound
        loop.fade(1, 0, 2)
      }
    },
    stopAllSounds: () => {
      setPausedSounds([])
      allSounds.forEach(({id}) => {
        const soundRef = soundsRefs.current[id]
        if (soundRef && soundRef.sound) {
          soundRef.sound.stop()
        }
      })
    },
    togglePause: (resetAllSounds=false) => {
      if (isPaused) {
        if (!resetAllSounds) {
          pausedSounds.forEach(({id, time}) => {
            const soundRef = soundsRefs.current[id]
            if (soundRef && soundRef.sound) {
              soundRef.sound.seek(time)
              soundRef.sound.play()
            }
          })
        }
        setPausedSounds([])
      } else {
        const paused = []
        allSounds.forEach(({id}) => {
          const soundRef = soundsRefs.current[id]
          if (soundRef && soundRef.sound && soundRef.sound.playing()) {
            paused.push({id, time: soundRef.sound.seek()})
            soundRef.sound.stop()
          }
        })
        setPausedSounds(paused)
      }
      setIsPaused(!isPaused)
    }
  }))
  return (
    <Fragment>
    { allSounds.map(sound => (
      <JukeboxItem
        key={'snd-' + sound.id}
        ref={x => soundsRefs.current[sound.id] = x}
        onLoad={onSoundLoaded}
        {...sound}
       />
    ))}
    </Fragment>
  )
})

export default Jukebox